import { useMutation, useQuery } from '@apollo/client';
import {
  CaretLeft,
  Eye,
  GearSix,
  PaperPlaneTilt,
  PencilSimpleLine,
} from '@phosphor-icons/react';
import { Flex, Tabs, TabsProps, Tooltip } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../AppContext';
import {
  FormStatus,
  FormSubmissionType,
  FormTheme,
} from '../../__generated__/graphql';
import {
  EVENT_DATA,
  EVENT_NAME,
  FORM_ID,
  ROUTES,
  TOAST_MESSAGE,
} from '../../common/constants';
import {
  getCookie,
  handleGraphQlError,
  triggerEvent,
} from '../../common/utils';
import CommonFormEditModal from '../../components/common/CommonFormEditModal';
import PublishSuccessModal from '../../components/common/PublishSuccessModal';
import UnpublishModal from '../../components/common/UnpublishModal';
import GleapBotPopup from '../../components/common/gleap/GleapBotPopup';
import AppMenuTour from '../../components/common/tours/AppMenuTour';
import { messageContext } from '../../components/context/AppContextHolder';
import CommonButton from '../../components/primitives/CommonButton';
import useRouter from '../../hooks/useRouter';
import { AppActionType, AppContextType } from '../../types/appContext.type';
import { FormSchemaType } from '../form/form.types';
import Results from '../results/Results';
import WorkFlow from '../workflow/WorkFlow';
import Editor from './editor/Editor';
import Share from './editor/Share';
import { PUBLISH_FORM, UPDATE_DRAFT_FORM_RESPONSE } from './graphql/mutations';
import { GET_FORM, GET_FORM_THEMES } from './graphql/queries';

export default function FormFlowWrapper() {
  const {
    navigate,
    location: { pathname },
  } = useRouter();

  const { params } = useRouter();
  const formId = params?.id as string;
  const [tourOpen, setTourOpen] = useState<boolean>(false);
  const [publishSuccessModalOpen, setPublishSuccessModalOpen] =
    useState<boolean>(false);
  const editorRef = useRef<HTMLDivElement>(null);
  const workflowRef = useRef<HTMLDivElement>(null);
  const shareRef = useRef<HTMLDivElement>(null);
  const resultsRef = useRef<HTMLDivElement>(null);
  const previewRef = useRef<HTMLDivElement>(null);
  const publishRef = useRef<HTMLDivElement>(null);

  // get form base url
  const FORM_BASE_URL = process.env.REACT_APP_FORM_BASE_URL!;

  const {
    dispatch,
    state: { formSettings },
  } = useContext(AppContext) as AppContextType;

  const onChange = (key: string) => {
    navigate(`${key}/${formId}`);
  };

  const { loading } = useQuery(GET_FORM_THEMES, {
    variables: {
      data: {
        formId,
      },
    },
    onCompleted: (res) => {
      dispatch({
        type: AppActionType.setAppThemes,
        data: res.formThemes?.data as FormTheme[],
      });
    },
  });

  const { loading: formLoading, data } = useQuery(GET_FORM, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        formId: formId as string,
      },
    },
    onCompleted: (res) => {
      const formDesigns = res.form?.data?.designs?.[0];
      const formStructure = res.form?.data?.structures?.[0]?.structure;

      dispatch({
        type: AppActionType.setFormSettings,
        data: {
          ...res.form?.data,
        },
      });
      dispatch({
        type: AppActionType.setActiveThemeIds,
        data: {
          activeThemeId: formDesigns?.themeId,
          activeThemeVariationId: formDesigns?.themeVariationId,
          activeMode: formDesigns?.themeMode,
          removeBranding: formDesigns?.removeBranding,
        },
      });
      dispatch({
        type: AppActionType.setFormItems,
        data: {
          items: formStructure as FormSchemaType[],
          activeId: formStructure?.[0]?.question_id,
        },
      });
      if (!getCookie('app-menu-tour')) {
        setTourOpen(true);
      }
    },
    onError: () => {
      navigate(ROUTES.NOT_FOUND);
    },
  });

  const [publishFormMutate, { loading: publishLoading }] = useMutation(
    PUBLISH_FORM,
    {
      fetchPolicy: 'network-only',
      variables: {
        where: {
          formId,
        },
      },
      onCompleted: (res) => {
        dispatch({
          type: AppActionType.setFormSettings,
          data: {
            ...res.publishForm?.data,
          },
        });
        // setAlertModalOpen(false);
        setPublishSuccessModalOpen(true);
      },
      onError: (err) => {
        handleGraphQlError(err?.message);
      },
    },
  );

  const items: TabsProps['items'] = [
    {
      key: ROUTES.EDITOR,
      label: <div ref={editorRef}>Editor</div>,
      children: <Editor />,
    },
    {
      key: ROUTES.WORKFLOW,
      label: <div ref={workflowRef}>Workflow</div>,
      children: <WorkFlow />,
    },
    {
      key: ROUTES.SHARE,
      label: <div ref={shareRef}>Share</div>,
      children: <Share />,
    },
    {
      key: ROUTES.RESULTS,
      label: <div ref={resultsRef}>Results</div>,
      children: <Results />,
    },
  ];

  const handlePublishBtn = () => {
    publishFormMutate();
  };

  // publish button variables
  const isPublished = formSettings?.status === FormStatus.Published;
  const isUpdated = formSettings?.isUpdated;
  const isDisabled = (isPublished && !isUpdated) || publishLoading;
  const buttonLabel = publishLoading
    ? 'Publishing...'
    : isPublished && isUpdated
      ? 'Republish'
      : 'Publish';

  useEffect(() => {
    if (formId) {
      localStorage.setItem(FORM_ID, formId);
    }
  }, [formId]);

  const LeftContent = () => {
    const [isEditModal, setIsEditModal] = useState(false);

    const handleCloseModal = () => {
      setIsEditModal(false);
    };

    const isLengthLimit = formSettings?.title
      ? formSettings?.title?.length > 30
      : false;

    const displayTitle = isLengthLimit
      ? formSettings?.title?.substring(0, 30) + '...'
      : formSettings?.title;

    return (
      <Flex className="form-flow-left" gap={8} align="center">
        <CommonButton
          shape="circle"
          size="small"
          shadow={false}
          icon={<CaretLeft className="pointer" size={20} />}
          onClick={() => navigate(ROUTES.MAIN)}
        ></CommonButton>
        <Flex align="center" gap={8}>
          <Paragraph className="mb-0 text-m text-content-primary">
            <Tooltip title={isLengthLimit ? formSettings?.title : ''}>
              {displayTitle}
            </Tooltip>
          </Paragraph>
          <Tooltip title="Edit">
            <PencilSimpleLine
              color="var(--content-secondary)"
              className="cursor-pointer"
              size={16}
              onClick={() => setIsEditModal(true)}
            />
          </Tooltip>
        </Flex>
        {isEditModal && (
          <CommonFormEditModal
            isVisible={isEditModal}
            onCancel={handleCloseModal}
            onConfirm={(isRefetch) => {
              handleCloseModal();
              if (isRefetch) {
                navigate(`${ROUTES.EDITOR}/${formId}?activeTab=form`);
              }
            }}
          />
        )}
      </Flex>
    );
  };

  const RightContent = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    useState<boolean>(false);
    const handleModalClose = () => {
      setIsModalOpen(false);
    };
    const [
      updateDraftFormResponse,
      { loading: updateDraftFormResponseLoading },
    ] = useMutation(UPDATE_DRAFT_FORM_RESPONSE);

    const handleFormPreviewBtn = () => {
      updateDraftFormResponse({
        variables: {
          where: {
            formId,
          },
        },
        onCompleted: (res) => {
          const response = res?.updateDraftForm;
          triggerEvent(
            EVENT_NAME.PREVIEW_BUTTON_CLICK,
            EVENT_DATA.PREVIEW_BUTTON_CLICK,
          );
          if (response?.updated && formSettings?.previewKey) {
            let previewType = formSettings?.previewType;
            if (formSettings?.previewType === FormSubmissionType.Voice) {
              previewType = FormSubmissionType.Conversation; // for default preview
            }
            window.open(
              `${FORM_BASE_URL}${ROUTES.FORM_PREVIEW}/${formSettings?.previewKey}?previewType=${previewType}`,
              '_blank',
            );
          } else {
            messageContext.error(TOAST_MESSAGE.previewNotAvailable);
          }
        },
        onError: (res) => {
          handleGraphQlError(res?.message);
        },
      });
    };

    return (
      <Flex gap={8} align="center">
        <GleapBotPopup />
        {isPublished && (
          <CommonButton
            type="text"
            shape="circle"
            onClick={() => {
              setIsModalOpen(true);
            }}
            className="shadow-hidden"
            icon={
              <GearSix color="var(--content-primary)" size={18} weight="fill" />
            }
          />
        )}
        <div ref={previewRef}>
          <CommonButton
            type="text"
            loading={updateDraftFormResponseLoading}
            icon={
              <Eye color="var(--content-primary)" size={16} weight="fill" />
            }
            onClick={handleFormPreviewBtn}
          >
            Preview
          </CommonButton>
        </div>
        <div ref={publishRef}>
          <CommonButton
            type="primary"
            iconPosition="end"
            icon={
              <PaperPlaneTilt
                color="var(--content-inverse-primary)"
                size={16}
                weight="fill"
              />
            }
            onClick={handlePublishBtn}
            disabled={isDisabled || updateDraftFormResponseLoading}
          >
            {buttonLabel}
          </CommonButton>
        </div>

        {isModalOpen && (
          <UnpublishModal
            open={isModalOpen}
            onOk={handleModalClose}
            onCancel={handleModalClose}
            onSuccess={handleModalClose}
          />
        )}
        {/* commenting for future use */}
        {/* <PublishAlertModal
          isVisible={alertModalOpen}
          onCancel={() => setAlertModalOpen(false)}
          onConfirm={() => {
            setAlertModalOpen(false);
            publishFormMutate();
          }}
          onReviewSettingsClick={() => {
            setAlertModalOpen(false);
            navigate(`${ROUTES.EDITOR}/${formId}?activeTab=settings`);
          }}
          title="Almost Ready to Publish!"
          description="Before you publish, check your form settings to ensure everything is just right. Customize your notifications, schedule, and more."
        /> */}
        {publishSuccessModalOpen && (
          <PublishSuccessModal
            isVisible={publishSuccessModalOpen}
            onCancel={() => setPublishSuccessModalOpen(false)}
            onConfirm={() => {
              setPublishSuccessModalOpen(false);
              // publishFormMutate();
            }}
          />
        )}
      </Flex>
    );
  };

  return (
    <div className="form-flow-wrapper">
      <section>
        <Tabs
          activeKey={`/${pathname?.split('/')?.[1]}`}
          items={items}
          className="header-tabs app-tabs"
          onChange={onChange}
          centered
          tabBarExtraContent={{
            left: <LeftContent />,
            right: <RightContent />,
          }}
        />
      </section>
      {tourOpen && (
        <AppMenuTour
          refs={[
            editorRef,
            workflowRef,
            shareRef,
            resultsRef,
            previewRef,
            publishRef,
          ]}
          open={tourOpen}
          onOpenChange={setTourOpen}
        />
      )}
    </div>
  );
}
