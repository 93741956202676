import { useLazyQuery } from '@apollo/client';
import { CaretLeft, UploadSimple } from '@phosphor-icons/react';
import { ConfigProvider, Flex, Form, Input, Upload, UploadFile } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import { UploadProps } from 'antd/es/upload';
import { useEffect, useState } from 'react';
import { ALLOWED_FILE_TYPES, ALLOWED_SIZE } from '../../../../common/constants';
import {
  commonUploadFile,
  validatePageBackgroundImage,
} from '../../../../common/utils';
import CommonButton from '../../../../components/primitives/CommonButton';
import CommonSwitch from '../../../../components/primitives/CommonSwitch';
import useRouter from '../../../../hooks/useRouter';
import {
  FormSchemaType,
  InputChangeTypes,
  PageLayoutType,
} from '../../../form/form.types';
import { FormFieldType } from '../../form.types';
import { GET_PAGE_BG_URL, GET_SIGNED_URL_PAGES } from '../../graphql/queries';
import FormFieldsPagination from '../utils/FormFieldsPagination';
import PageLayoutOption from '../utils/PageLayoutOption';
import { CommonPageTypes } from './pages.types';

export const EditWelcomePage: React.FC<
  CommonPageTypes & {
    onChange: ({ id, path, value }: InputChangeTypes) => void;
    itemList: FormSchemaType[];
    paginationProps: {
      total: number;
      onChange: (item: FormSchemaType) => void;
    };
  }
> = ({ onBack, item, onChange, paginationProps, itemList }) => {
  const { params } = useRouter();
  const formId = params?.id as string;
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const [getSignedUrlMutate, { loading }] = useLazyQuery(GET_SIGNED_URL_PAGES, {
    fetchPolicy: 'network-only',
    onError: () => {},
  });

  const [getImageURL] = useLazyQuery(GET_PAGE_BG_URL, {
    fetchPolicy: 'network-only',
    onError: () => {},
  });

  useEffect(() => {
    if (item?.bg_image_url) {
      setFileList([
        {
          url: item.bg_image_url,
          uid: '-1',
          name: item?.bg_file_name as string,
          status: 'done',
        },
      ]);
    }
  }, []);

  const handleUpload =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async ({ file, onSuccess, onError }: any) => {
      const validateMessage = validatePageBackgroundImage(
        file,
        ALLOWED_SIZE.pageBgImage,
        ALLOWED_FILE_TYPES.pageBgImage,
      );

      if (validateMessage) {
        onError(new Error(validateMessage));
        return;
      }

      getSignedUrlMutate({
        variables: {
          data: {
            formId: formId as string,
            pageType: FormFieldType.WELCOME_PAGE,
            fileName: file?.name,
            contentLength: file?.size,
            questionId: item?.question_id as string,
          },
        },
        onCompleted: async (res) => {
          const signedUrl = res.getFormPageImageUploadSignedUrl?.signedUrl;
          const key = res.getFormPageImageUploadSignedUrl?.key;
          if (signedUrl && key) {
            const success = await commonUploadFile(signedUrl, file);
            if (success) {
              getImageURL({
                variables: {
                  data: {
                    imageKey: key,
                  },
                },
                onCompleted: (res) => {
                  onChange({
                    id: item?.question_id || '',
                    path: ['bg_image', 'bg_image_url', 'bg_file_name'],
                    value: [
                      key as string,
                      res.getFormStructurePagesImageUrl?.imageUrl as string,
                      file.name,
                    ],
                  });
                  onSuccess(null, file);
                },
              });
            } else {
              onError(new Error('Upload failed'));
            }
          }
        },
      });
    };

  // upload props for welcome page
  const uploadProps: UploadProps = {
    accept: ALLOWED_FILE_TYPES.pageBgImage,
    name: 'file',
    multiple: false,
    fileList,
    customRequest: handleUpload,
    onRemove: () => {
      onChange({
        id: item?.question_id || '',
        path: ['bg_image_url', 'bg_image'],
        value: ['', ''],
      });
    },
    onChange: (info: Parameters<Required<UploadProps>['onChange']>[0]) => {
      setFileList(info.fileList);
    },
  };
  return (
    <section>
      <ConfigProvider
        theme={{
          token: {
            colorBgContainer: 'var(--surface-secondary)',
            colorPrimary: '#a9aeb1',
            colorLink: '#a9aeb1',
          },
          components: {
            Form: {
              labelColor: 'var(--content-secondary)',
              labelFontSize: 14,
              verticalLabelPadding: 0,
              itemMarginBottom: 0,
            },
            Input: {
              borderRadius: 16,
              colorText: 'var(--content-primary)',
              colorBorder: 'var(--border-primary)',
            },
            Upload: {},
          },
        }}
      >
        <Flex gap={12} wrap align="center" className="scroll-wrapper-header">
          <CommonButton
            type="text"
            onClick={() => onBack()}
            shape="circle"
            shadow={false}
            icon={<CaretLeft color="var(--content-primary)" size={20} />}
          />
          <Paragraph className="mb-0 text-content-primary text-base semi-bold">
            Welcome Page
          </Paragraph>
        </Flex>
        <div className="scroll-wrapper-body">
          <Flex
            gap={16}
            wrap
            justify="space-between"
            align="flex-start"
            className="mb-24"
          >
            <Flex vertical>
              <Paragraph className="mb-0 text-base semi-bold text-content-primary">
                Show Welcome Page
              </Paragraph>
              <Paragraph className="mb-0 text-content-tertiary medium">
                Greet respondents with an introductory page when they access the
                form.
              </Paragraph>
            </Flex>
            <CommonSwitch
              type="primary"
              checked={item?.is_display}
              onChange={(checked) =>
                onChange({
                  id: item?.question_id || '',
                  path: ['is_display'],
                  value: [checked],
                })
              }
            />
          </Flex>
          <Flex vertical gap={20}>
            <Form.Item label="Page Layout" labelCol={{ span: 24 }}>
              <Flex gap={12}>
                <PageLayoutOption
                  value={item?.page_layout as PageLayoutType}
                  onChange={(val) => {
                    onChange({
                      id: item?.question_id || '',
                      path: ['page_layout'],
                      value: [val],
                    });
                  }}
                />
              </Flex>
            </Form.Item>
            <Form.Item label="Image" labelCol={{ span: 24 }}>
              <Upload {...uploadProps}>
                {fileList.length >= 1 ? null : (
                  <CommonButton
                    type="text"
                    icon={<UploadSimple />}
                    disabled={loading}
                  >
                    Upload
                  </CommonButton>
                )}
              </Upload>
            </Form.Item>
            <Form.Item label="Title" labelCol={{ span: 24 }}>
              <Input
                placeholder="Type..."
                value={item?.title}
                onChange={(e) => {
                  onChange({
                    id: item?.question_id || '',
                    path: ['title'],
                    value: [e.target.value],
                  });
                }}
              />
            </Form.Item>
            <Form.Item label="Body" labelCol={{ span: 24 }}>
              <Input.TextArea
                autoSize={{ minRows: 3 }}
                placeholder="Type..."
                value={item?.body}
                onChange={(e) => {
                  onChange({
                    id: item?.question_id || '',
                    path: ['body'],
                    value: [e.target.value],
                  });
                }}
              />
            </Form.Item>
            <Form.Item label="Button" labelCol={{ span: 24 }}>
              <Input
                placeholder="Type..."
                value={item?.cta_name}
                onChange={(e) => {
                  onChange({
                    id: item?.question_id || '',
                    path: ['cta_name'],
                    value: [e.target.value],
                  });
                }}
              />
            </Form.Item>
          </Flex>
        </div>
        {itemList?.length > 0 && (
          <div className="absolute bottom-32 w-full">
            <FormFieldsPagination
              itemList={itemList}
              currentItem={item as FormSchemaType}
              total={paginationProps.total}
              onChange={(item) => paginationProps.onChange(item)}
            />
          </div>
        )}
      </ConfigProvider>
    </section>
  );
};
