import { ConfigProvider, Menu, MenuProps } from 'antd';
import { useContext } from 'react';
import { Outlet } from 'react-router';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import useRouter from '../../hooks/useRouter';
import { AppContextType } from '../../types/appContext.type';
type MenuItem = Required<MenuProps>['items'][number];

export default function ProfileWrapper() {
  const { navigate, location } = useRouter();
  const {
    state: { currentUser },
  } = useContext(AppContext) as AppContextType;
  const items: MenuItem[] = [
    {
      key: ROUTES.ACCOUNT,
      label: 'My Account',
    },
    {
      key: ROUTES.WORKSPACE_SETTING,
      label: currentUser.defaultWorkspace?.name,
      children: [
        {
          key: ROUTES.INTEGRATION,
          label: 'Integrations',
          disabled: true,
        },
        {
          key: ROUTES.WORKSPACE_SETTING,
          label: 'Workspace settings',
          disabled: true,
        },
        {
          key: ROUTES.WORKSPACE_MEMBERS,
          label: 'Workspace members',
          disabled: true,
        },
        {
          key: ROUTES.PLAN_BILLING,
          label: 'Plan & billing',
        },
      ],
    },
  ];

  return (
    <div className="account-wrapper">
      <div className="account-nav">
        <ConfigProvider
          theme={{
            components: {
              Menu: {
                groupTitleColor: 'var(--content-primary)',
                itemBg: 'var(--surface-primary)',
                margin: 16,
                itemHoverColor: 'var(--content-primary)',
                itemSelectedColor: 'var(--content-primary)',
                itemHoverBg: 'var(--surface-secondary)',
                itemSelectedBg: 'var(--surface-secondary)',
                itemActiveBg: 'var(--surface-secondary)',
                itemColor: 'var(--content-primary)',
                padding: 24,
                subMenuItemBg: 'var(--surface-primary)',
                fontSize: 16,
                colorSplit: 'transparent',
                itemBorderRadius: 12,
              },
            },
          }}
        >
          <Menu
            selectedKeys={[location.pathname]}
            mode="inline"
            onClick={(info) => navigate(info.key)}
            items={items}
            expandIcon={null}
            openKeys={[ROUTES.WORKSPACE_SETTING]}
          />
        </ConfigProvider>
      </div>
      <div className="account-plan-detail">
        <Outlet />
      </div>
      {/* <SubscribeModal /> */}
    </div>
  );
}
