import { Plus } from '@phosphor-icons/react';
import { Flex } from 'antd';
import Title from 'antd/es/typography/Title';
import CommonButton from '../../../../components/primitives/CommonButton';

export default function EmptyFormList({
  onAddQuestion,
}: {
  onAddQuestion: () => void;
}) {
  return (
    <Flex vertical gap={24} align="center">
      <Title
        level={3}
        className="text-content-primary mb-0 font-secondary semi-bold"
      >
        Start by adding your first question
      </Title>
      <CommonButton
        type="primary"
        onClick={onAddQuestion}
        icon={<Plus size={16} color="var(--content-inverse-primary)" />}
      >
        Add Question
      </CommonButton>
    </Flex>
  );
}
