import { useMutation, useQuery } from '@apollo/client';
import { Check, Info, X } from '@phosphor-icons/react';
import { Flex, Skeleton, Tooltip } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import { lowerCase, map, startCase } from 'lodash';
import { useRef, useState } from 'react';
import {
  SubscriptionPlan,
  SubscriptionPlanType,
} from '../../__generated__/graphql';
import { DEFAULT_PLAN_SORT } from '../../common/constants';
import { currencyCodeToSymbol, handleGraphQlError } from '../../common/utils';
import PremiumLogo from '../../components/common/PremiumLogo';
import UpgradeSuccessful from '../../components/common/UpgradeSuccessful';
import CommonButton from '../../components/primitives/CommonButton';
import CommonTag from '../../components/primitives/CommonTag';
import { ACTIVATE_SUBSCRIPTION_PLAN } from './graphql/mutations';
import { GET_FEATURE_LIST, GET_PLAN_LIST } from './graphql/queries';
import { PlanFeature, PlanType } from './profile.types';

export default function PlanBilling() {
  const [upgradeModal, setUpgradeModal] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const FeatureListItem = ({ list }: { list: string[] }) => {
    return (
      <ul className="mt-16">
        {map(list, (text) => {
          return (
            <li>
              <Check color="var(--system-green-dark)" size={20} weight="bold" />
              {text}
            </li>
          );
        })}
        <li
          onClick={handleScroll}
          className="cursor-pointer w-max-content text-underline"
        >
          <svg height={16} width={16}></svg>& More...
        </li>
      </ul>
    );
  };

  const PlanList = () => {
    const { loading, refetch, data } = useQuery(GET_PLAN_LIST, {
      variables: {
        sort: DEFAULT_PLAN_SORT,
      },
    });
    const [activateSubscriptionPlan, { loading: activateLoading }] =
      useMutation(ACTIVATE_SUBSCRIPTION_PLAN, {
        onError() {},
      });

    const handleActivatePlan = (id: string) => {
      activateSubscriptionPlan({
        variables: {
          data: {
            subscriptionPlanId: id,
          },
        },
        onCompleted: () => {
          refetch();
          setUpgradeModal(true);
        },
      });
    };

    if (loading) {
      return <Skeleton active className="mb-24" />;
    }
    return (
      <div className="subscribe-modal zinq-modal">
        <div className="zinq-modal-content">
          <div className="subscribe-modal-wrapper">
            <div className="subscribe-plan-card-wrapper">
              {map(
                data?.subscriptionPlanKeyFeatures?.data,
                (item: SubscriptionPlan) => {
                  return (
                    <div className="subscribe-plan-card">
                      {lowerCase(item.label!) !== PlanType.FREE ? (
                        <div className="tag premium mb-8">
                          PREMIUM <PremiumLogo />
                        </div>
                      ) : (
                        <div className="tag mb-8">{item.label}</div>
                      )}

                      <Title level={2}>
                        {lowerCase(item.label!) !== PlanType.FREE &&
                        item.type == SubscriptionPlanType.Monthly
                          ? `Beta - ${currencyCodeToSymbol(item!.currency!)}${item!.price!}/month*`
                          : `${currencyCodeToSymbol(item.currency || '')}${item.price || 0}`}
                      </Title>
                      {/* <p className="text-sm mt-16">{item.description}</p> */}
                      {item.keyFeatures && (
                        <FeatureListItem list={item.keyFeatures as string[]} />
                      )}
                      {lowerCase(item.label!) !== PlanType.FREE ? (
                        <div className="plan-button-account-page">
                          {item.isSubscribed ? (
                            <CommonTag className="w-max-content">
                              Current Plan
                            </CommonTag>
                          ) : (
                            <CommonButton
                              type="primary"
                              onClick={() => handleActivatePlan(item.id!)}
                              loading={activateLoading}
                            >
                              Upgrade Now For Free
                            </CommonButton>
                          )}
                        </div>
                      ) : null}
                    </div>
                  );
                },
              )}
            </div>
            <div className="text-center text-sm mt-24 subscribe-modal-footer-text">
              *Premium is available for FREE while we are in beta.
            </div>
          </div>
        </div>
      </div>
    );
  };

  const FeatureList = () => {
    const { loading, refetch, data } = useQuery(GET_FEATURE_LIST, {
      variables: {
        sort: DEFAULT_PLAN_SORT,
      },
    });
    const [activateSubscriptionPlan, { loading: activateLoading }] =
      useMutation(ACTIVATE_SUBSCRIPTION_PLAN, {
        onError() {},
      });

    const handleActivatePlan = (id: string) => {
      activateSubscriptionPlan({
        variables: {
          data: {
            subscriptionPlanId: id,
          },
        },
        onCompleted: () => {
          refetch();
          setUpgradeModal(true);
        },
        onError: (err) => {
          handleGraphQlError(err.message);
          refetch();
        },
      });
    };

    const renderFeatureItem = (plan: SubscriptionPlan, keys: string[]) => {
      const planValue = plan?.features
        ?.filter((val: PlanFeature) => keys.includes(val.key))
        .map((val: PlanFeature) => val.featureLabel);

      if (!planValue || planValue.length === 0) return null;

      return planValue.map((label: string, index: number) => {
        switch (typeof label) {
          case 'boolean':
            return label ? (
              <Check color="#09091D" size={20} weight="bold" />
            ) : (
              <X color="#120B3B99" size={20} />
            );
          default:
            return (
              <p className="mb-0" key={index}>
                {String(label)}
              </p>
            );
        }
      });
    };

    const handleLabelRender = (item: PlanFeature) => {
      return (
        <Flex gap={8}>
          <Paragraph className="mb-0 medium text-sm flex items-center gap-8 no-wrap">
            {item.title}
            {item?.description && (
              <Tooltip title={item.description}>
                <Info size={16} className="cursor-pointer flex-shrink-0" />
              </Tooltip>
            )}
          </Paragraph>
          {item.comingSoon && (
            <CommonButton
              type="default"
              size="small"
              className="coming-soon-button"
            >
              Coming Soon
            </CommonButton>
          )}
        </Flex>
      );
    };

    if (loading) {
      return <Skeleton active />;
    }
    const freePlan = data?.subscriptionPlans?.data?.filter(
      (item) => lowerCase(item?.label || '') === PlanType.FREE,
    )?.[0];

    const premiumPlan = data?.subscriptionPlans?.data?.filter(
      (item) => lowerCase(item?.label || '') === PlanType.PREMIUM,
    )?.[0];

    return (
      <div className="feature-wrapper" ref={ref}>
        <div className="feature-table mt-32">
          <table>
            <thead>
              <tr>
                <th>
                  <Title level={2} className="mb-0 medium">
                    Compare plans & features
                  </Title>
                </th>
                <th>
                  <Title level={4} className="mb-0 medium">
                    {startCase(lowerCase(freePlan?.label || ''))}
                  </Title>
                </th>
                <th>
                  <Flex vertical gap={8} align="center">
                    <Flex gap={8} align="center" justify="center">
                      <Title level={4} className="mb-0 medium">
                        Premium (
                        {`Beta - ${currencyCodeToSymbol(premiumPlan!.currency!)}${premiumPlan!.price!}/month*`}
                        )
                      </Title>
                      <PremiumLogo />
                    </Flex>
                    {premiumPlan?.isSubscribed ? (
                      <CommonTag className="w-max-content">
                        Current Plan
                      </CommonTag>
                    ) : (
                      <CommonButton
                        type="primary"
                        onClick={() =>
                          handleActivatePlan(premiumPlan?.id || '')
                        }
                        loading={activateLoading}
                      >
                        Upgrade Now For Free
                      </CommonButton>
                    )}
                  </Flex>
                </th>
              </tr>
            </thead>
            <tbody>
              {map(
                data?.subscriptionPlans?.data?.[0]?.categoryWiseGroupedFeatures,
                (item) => {
                  return (
                    <>
                      <tr>
                        <th colSpan={3}>
                          <Paragraph className="mb-0 text-m medium">
                            {item?.title}
                          </Paragraph>
                        </th>
                      </tr>
                      {map(item?.list, (subItem) => {
                        return (
                          <>
                            {subItem?.title && (
                              <tr>
                                <th colSpan={3}>
                                  <Paragraph className="mb-0 text-base medium">
                                    {subItem?.title}
                                  </Paragraph>
                                </th>
                              </tr>
                            )}
                            {map(subItem?.features, (item) => {
                              return (
                                premiumPlan &&
                                freePlan &&
                                item!.key! && (
                                  <tr>
                                    <td>
                                      {handleLabelRender(item as PlanFeature)}
                                    </td>
                                    <td>
                                      {renderFeatureItem(freePlan, [
                                        item!.key!,
                                      ])}
                                    </td>
                                    <td>
                                      {renderFeatureItem(premiumPlan, [
                                        item!.key!,
                                      ])}
                                    </td>
                                  </tr>
                                )
                              );
                            })}
                          </>
                        );
                      })}
                    </>
                  );
                },
              )}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={2}></td>
                <td className="text-center">
                  {!premiumPlan?.isSubscribed && (
                    <CommonButton
                      type="primary"
                      onClick={() => handleActivatePlan(premiumPlan?.id || '')}
                      loading={activateLoading}
                    >
                      Upgrade Now For Free
                    </CommonButton>
                  )}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div className="plan-and-billing">
      <PlanList />
      <FeatureList />
      <UpgradeSuccessful
        isVisible={upgradeModal}
        onClose={() => {
          setUpgradeModal(false);
        }}
      />
    </div>
  );
}
