// import { gql } from '../../../__generated__';

import { gql } from '../../../__generated__';

export const GET_CURRENT_USER = gql(`
 query CurrentUser {
  currentUser {
    companyName
    email
    phoneNumber
    name
    roles
    profileImage
    isNewUser
    countryCode
    defaultWorkspace {
      id
      uuid
      name
      storageUsed
    }
    subscriptionPlan {
        features
    }
  }
}
`);

export const CHECK_PHONE_NUMBER = gql(`
  query Query($data: IsPhoneNumberInUseInput!) {
  isPhoneNumberInUse(data: $data) {
    isPhoneNumberInUsed
  }
}`);
//   query refreshToken($data: RefreshTokenInput!) {
//     refreshToken(data: $data) {
//       token
//       user {
//         id
//         email
//         profileImage
//         name
//         firstName
//         lastName
//       }
//     }
//   }
// `);
