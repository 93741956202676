import { ThemeConfig } from 'antd';

const theme: ThemeConfig = {
  token: {
    fontFamily: 'Inter',
    colorText: '',
    fontSize: 14,
    controlPaddingHorizontal: 12,
    colorPrimary: '#ccd0d2',
    margin: 0,
    colorLink: '#2e93ff',
  },
  components: {
    Button: {
      colorError: '#E50013',
    },
    Layout: {
      headerBg: '#ffffff',
      siderBg: 'var(--neutrals)',
    },
    Upload: {
      actionsColor: 'var(--danger-on-surface)',
    },
    Segmented: {
      trackBg: '#e5e8e9',
      itemColor: '#767F83',
      itemSelectedColor: '#343B3F',
      itemSelectedBg: '#ffffff',
    },
    Switch: {
      trackHeight: 24,
      handleSize: 19,
      trackMinWidth: 40,
    },
    Statistic: { contentFontSize: 14 },
    Divider: {
      textPaddingInline: 16,
      colorSplit: 'var(--border-primary)',
    },
    Select: {
      controlHeight: 40,
      borderRadius: 16,
      borderRadiusLG: 16,
      fontSizeLG: 16,
      colorText: 'var(--content-primary)',
      colorBorder: 'var(--border-primary)',
      optionActiveBg: 'var(--surface-tertiary)',
      optionSelectedBg: 'var(--surface-tertiary)',
      selectorBg: 'var(--surface-secondary)',
    },
    Menu: {
      itemBg: 'var(--neutrals1)',
      colorText: 'var(--neutrals8)',
      itemSelectedBg: 'var(--neutrals)',
      itemSelectedColor: 'var(--primary)',
      itemHoverBg: 'var(--neutrals)',
      itemHoverColor: 'var(--primary)',
    },
    Form: {
      fontSize: 14,
      colorError: 'var(--danger-on-surface)',
      labelRequiredMarkColor: 'var(--danger-on-surface)',
      labelColor: 'var(--content-secondary)',
    },
    InputNumber: {
      controlWidth: 100,
      controlHeight: 40,
    },
    DatePicker: {
      colorText: 'var(--neutrals11)',
      controlHeight: 40,
      colorBgContainerDisabled: 'var(--surface-tertiary)',
    },
    Input: {
      controlHeight: 40,
      borderRadius: 16,
      colorBgContainerDisabled: 'var(--surface-tertiary)',
      colorBgContainer: 'var(--surface-secondary)',
      colorBorder: 'var(--border-primary)',
    },
    Progress: {
      defaultColor: 'var(--primary)',
    },
    Collapse: {
      headerBg: 'var(--surface-secondary)',
      contentBg: 'var(--neutrals)',
    },
    Tabs: {
      inkBarColor: 'var(--content-primary)',
      itemSelectedColor: 'var(--content-primary)',
      itemColor: 'var(--content-tertiary)',
      titleFontSize: 16,
      itemHoverColor: 'var(--content-primary)',
    },
    Tooltip: {
      fontSize: 12,
      colorBgSpotlight: 'var(--surface-inverse-primary)',
      colorTextLightSolid: 'var(--content-inverse-primary)',
    },
    Typography: {
      fontSizeHeading1: 32,
      fontSizeHeading2: 24,
      fontSizeHeading3: 20,
      fontSizeHeading4: 18,
      fontSizeHeading5: 16,
    },
  },
};

export default theme;
