import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  CaretLeft,
  CopySimple,
  FacebookLogo,
  LinkedinLogo,
  Plus,
  QrCode,
  Trash,
  TwitterLogo,
  UploadSimple,
} from '@phosphor-icons/react';
import {
  Col,
  ConfigProvider,
  Divider,
  Flex,
  Form,
  Image,
  Input,
  Popconfirm,
  Row,
  Segmented,
  Tooltip,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import { isEmpty, split, trim } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import {
  FormShare,
  FormShareType,
  FormShareUpdateInput,
} from '../../../../__generated__/graphql';
import { AppContext } from '../../../../AppContext';
import { ReactComponent as IframeIcon } from '../../../../assets/svg/embed-type/iframe.svg';
import { ReactComponent as PopoverIcon } from '../../../../assets/svg/embed-type/popover.svg';
import { ReactComponent as PopupIcon } from '../../../../assets/svg/embed-type/popup.svg';
import { ReactComponent as SlideTabIcon } from '../../../../assets/svg/embed-type/slide-tab.svg';
import { ReactComponent as SliderIcon } from '../../../../assets/svg/embed-type/slider.svg';
import {
  ALLOWED_FILE_TYPES,
  ALLOWED_SIZE,
  DEFAULT_AUTO_SAVE_TIME,
  ROUTES,
} from '../../../../common/constants';
import {
  commonUploadFile,
  copyToClipboard,
  getFormLink,
  getFormTypeOptions,
  handleGraphQlSuccess,
  handleSocialShare,
  hasFeatureAccess,
  validatePageBackgroundImage,
} from '../../../../common/utils';
import CommonQRCodeModal from '../../../../components/common/CommonQRCodeModal';
import PremiumLogo from '../../../../components/common/PremiumLogo';
import CommonButton from '../../../../components/primitives/CommonButton';
import CommonSwitch from '../../../../components/primitives/CommonSwitch';
import useRouter from '../../../../hooks/useRouter';
import { AppContextType } from '../../../../types/appContext.type';
import { EmbedTypes, SocialShareType } from '../../../form/form.types';
import { FeatureKeys } from '../../../profile/profile.types';
import { DELETE_FORM_SHARE, UPDATE_FORM_SHARE } from '../../graphql/mutations';
import {
  GET_SHARE_DETAIL,
  GET_SIGNED_URL_OG_SHARE_IMAGE,
} from '../../graphql/queries';
import DeleteShareMessage from '../utils/DeleteShareMessage';
import DimensionInput from '../utils/DimensionInput';
import FormLoading from '../utils/FormLoading';
import PremiumLabel from '../utils/PremiumLabel';
import { CommonPageTypes } from './pages.types';

export default function EditShare({ onBack }: CommonPageTypes) {
  const [details, setDetails] = useState<FormShare>();
  const [form] = Form.useForm();
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [link, setLink] = useState<string>('');
  const [qrModalVisible, setQrModalVisible] = useState(false);
  const { params, navigate } = useRouter();
  const shareId = params?.shareId as string;
  const [count, setCount] = useState(0);
  const {
    state: { currentUser },
  } = useContext(AppContext) as AppContextType;

  const hasShareAccess = hasFeatureAccess(
    currentUser?.subscriptionPlan?.features,
    FeatureKeys.MULTI_FORM_LINK,
  );

  const [updateFormShare, { loading: updateLoading }] = useMutation(
    UPDATE_FORM_SHARE,
    {
      fetchPolicy: 'network-only',

      onError: () => {},
    },
  );

  const [getShareDetails, { loading }] = useLazyQuery(GET_SHARE_DETAIL, {
    fetchPolicy: 'network-only',
    onError: () => {},
  });

  const [getSignedUrlMutate, { loading: uploadLoading }] = useLazyQuery(
    GET_SIGNED_URL_OG_SHARE_IMAGE,
    {
      fetchPolicy: 'network-only',
      onError: () => {},
    },
  );

  const [deleteFormShare, { loading: deleteLoading }] = useMutation(
    DELETE_FORM_SHARE,
    {
      onError: () => {},
    },
  );

  const menuItems = [
    {
      label: 'iframe',
      value: EmbedTypes.IFRAME,
      icon: <IframeIcon />,
    },
    {
      label: <PremiumLabel text="Popup" />,
      value: EmbedTypes.POPUP,
      icon: <PopupIcon />,
      disabled: true,
    },
    {
      label: <PremiumLabel text="Slider" />,
      value: EmbedTypes.SLIDER,
      icon: <SliderIcon />,
      disabled: true,
    },
    {
      label: <PremiumLabel text="Popover" />,
      value: EmbedTypes.POPOVER,
      icon: <PopoverIcon />,
      disabled: true,
    },
    {
      label: <PremiumLabel text="Side tab" />,
      value: EmbedTypes.SIDE_TAB,
      icon: <SlideTabIcon />,
      disabled: true,
    },
  ];

  const handleUpload =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async ({ file, onSuccess, onError }: any) => {
      const validateMessage = validatePageBackgroundImage(
        file,
        ALLOWED_SIZE.ogShareImage,
        ALLOWED_FILE_TYPES.ogShareImage,
      );

      if (validateMessage) {
        onError(new Error(validateMessage));
        return;
      }

      getSignedUrlMutate({
        variables: {
          data: {
            formShareId: shareId,
            imageType: file?.type,
            fileName: file?.name,
            contentLength: file?.size,
          },
        },
        onCompleted: async (res) => {
          const signedUrl = res.getFormOgImageUploadSignedUrl?.signedUrl;
          const key = res.getFormOgImageUploadSignedUrl?.key;
          if (signedUrl && key) {
            const success = await commonUploadFile(signedUrl, file);
            if (success) {
              onSuccess(key, file);
            } else {
              onError(new Error('Upload failed'));
            }
          }
        },
      });
    };

  useEffect(() => {
    if (shareId) {
      getShareDetails({
        variables: {
          where: {
            formShareId: shareId,
          },
        },
        onCompleted: (res) => {
          const formData = res.formShare?.data;
          setCount(res.formShare?.count || 0);
          if (formData) {
            setDetails(formData as FormShare);
            setLink(getFormLink(formData?.key || '', formData?.embedConfigs));
          }
        },
        onError: () => {
          navigate(ROUTES.NOT_FOUND);
        },
      });
    }
  }, [shareId]);

  const handleAutoSave = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    const allFields: FormShareUpdateInput = form.getFieldsValue();
    setLink(getFormLink(details?.key || '', allFields?.embedConfigs)); // immediate update link for copy button
    timerRef.current = setTimeout(() => {
      updateFormShare({
        variables: {
          where: {
            formShareId: shareId,
          },
          data: {
            ...allFields,
            title: trim(allFields.title || '') ? allFields.title : 'Untitled',
          },
        },
      });
    }, DEFAULT_AUTO_SAVE_TIME);
  };

  const handleDeleteImage = (file: UploadFile) => {
    setFileList((prev) => prev.filter((item) => item.uid !== file.uid));
    form.setFieldsValue({
      ogThumbnail: null,
    });
    handleAutoSave();
  };
  // upload props for welcome page
  const uploadProps: UploadProps = {
    accept: ALLOWED_FILE_TYPES.ogShareImage,
    name: 'file',
    multiple: false,
    fileList,
    customRequest: handleUpload,
    // onRemove: () => {},
    onChange: (info: Parameters<Required<UploadProps>['onChange']>[0]) => {
      setFileList(info.fileList);
    },
    itemRender: (originNode, file) => {
      let blobUrl = '';
      if (file?.originFileObj) {
        blobUrl = URL.createObjectURL(file.originFileObj);
      }

      return (
        <Flex justify="space-between" align="center" className="relative">
          <Image
            src={blobUrl || file?.url}
            width={100}
            preview={{
              toolbarRender: () => null,
              mask: (
                <Flex gap={8}>
                  <EyeOutlined />
                  <DeleteOutlined
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteImage(file);
                    }}
                  />
                </Flex>
              ),
            }}
          />
        </Flex>
      );
    },
  };

  useEffect(() => {
    form.setFieldsValue(details);
    const url = form.getFieldValue('ogThumbnailUrl');
    if (url) {
      setFileList([
        {
          uid: '-1',
          name: split(url, '/').pop() || '',
          status: 'done',
          url: url,
        },
      ]);
    }
  }, [details, form]);

  const handleQRCode = () => {
    setQrModalVisible(true);
  };

  const handleCloseQRModal = () => {
    setQrModalVisible(false);
  };

  const handleDelete = (id: string) => {
    deleteFormShare({
      variables: {
        where: {
          formShareId: id,
        },
      },
      onCompleted: (res) => {
        handleGraphQlSuccess(res.deleteFormShare?.message);
        onBack();
      },
    });
  };

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#a9aeb1',
          },
          components: {
            Form: {
              labelColor: 'var(--content-secondary)',
              labelFontSize: 14,
              verticalLabelPadding: 0,
              itemMarginBottom: 0,
              fontWeightStrong: 500,
            },
            Input: {
              borderRadius: 16,
              colorBorder: 'var(--border-primary)',
              colorText: 'var(--content-primary)',
              colorBgContainer: 'var(--surface-secondary)',
            },
            InputNumber: {
              borderRadius: 16,
              colorText: 'var(--content-primary)',
              colorBorder: 'var(--border-primary)',
              colorBgContainer: 'var(--surface-secondary)',
            },
            Select: {
              borderRadius: 16,
              colorText: 'var(--content-primary)',
              colorBorder: 'var(--border-primary)',
              optionActiveBg: 'var(--surface-tertiary)',
              optionSelectedBg: 'var(--surface-tertiary)',
              colorBgContainer: '#ff0000',
            },
            DatePicker: {
              borderRadius: 16,
              colorBorder: 'var(--border-primary)',
            },
          },
        }}
      >
        <div className="scroll-wrapper sharing">
          {!loading ? (
            <Form
              autoComplete="off"
              initialValues={details}
              form={form}
              onFieldsChange={handleAutoSave}
              className="edit-share-form"
            >
              <Flex
                gap={8}
                wrap
                align="center"
                justify="space-between"
                className="scroll-wrapper-header"
              >
                <Flex gap={12} align="center">
                  <CommonButton
                    type="text"
                    onClick={() => onBack()}
                    shape="circle"
                    shadow={false}
                    icon={
                      <CaretLeft color="var(--content-primary)" size={20} />
                    }
                  />
                  <Form.Item name="title" className="w-full">
                    <Input
                      variant="borderless"
                      className="text-m semi-bold text-content-primary"
                      placeholder="Type here..."
                    />
                  </Form.Item>
                </Flex>
                {count <= 2 && !hasShareAccess ? null : (
                  <Flex wrap gap={24} align="center">
                    <Tooltip title="Delete">
                      <Popconfirm
                        title={<DeleteShareMessage />}
                        okText="Yes"
                        placement="right"
                        className="hover-show"
                        onConfirm={() => handleDelete(details?.id as string)}
                        okButtonProps={{
                          block: true,
                          icon: <DeleteOutlined />,
                          danger: true,
                          loading: deleteLoading,
                        }}
                        cancelButtonProps={{
                          block: true,
                          disabled: deleteLoading,
                        }}
                        icon={null}
                      >
                        <CommonButton
                          type="text"
                          shape="circle"
                          shadow={false}
                          loading={deleteLoading}
                          icon={
                            <Trash color="var(--danger-on-surface)" size={20} />
                          }
                        />
                      </Popconfirm>
                    </Tooltip>
                  </Flex>
                )}
              </Flex>
              <div className="scroll-wrapper-body">
                <Row gutter={[12, 24]}>
                  <Col lg={14} xl={16}>
                    <Input placeholder="Link here..." value={link} disabled />
                  </Col>
                  <Col lg={10} xl={8}>
                    <CommonButton
                      type="text"
                      icon={
                        <CopySimple color="var(--content-primary)" size={16} />
                      }
                      block
                      onClick={(e) => {
                        e.stopPropagation();
                        copyToClipboard(link);
                      }}
                    >
                      {details?.type === FormShareType.Embed
                        ? 'Copy Code'
                        : 'Copy Link'}
                    </CommonButton>
                  </Col>
                  {details?.type === FormShareType.Link && (
                    <Col span={24}>
                      <Flex gap={12} justify="flex-start" align="center">
                        <Tooltip title="Share on Facebook">
                          <CommonButton
                            type="text"
                            shape="circle"
                            icon={<FacebookLogo size={24} />}
                            onClick={() =>
                              handleSocialShare(SocialShareType.FACEBOOK, link)
                            }
                          />
                        </Tooltip>
                        <Tooltip title="Share on Twitter">
                          <CommonButton
                            type="text"
                            shape="circle"
                            icon={<TwitterLogo size={24} />}
                            onClick={() =>
                              handleSocialShare(SocialShareType.TWITTER, link)
                            }
                          />
                        </Tooltip>
                        <Tooltip title="Share on LinkedIn">
                          <CommonButton
                            type="text"
                            shape="circle"
                            icon={<LinkedinLogo size={24} />}
                            onClick={() =>
                              handleSocialShare(SocialShareType.LINKEDIN, link)
                            }
                          />
                        </Tooltip>
                        <Tooltip title="Generate QR Code">
                          <CommonButton
                            type="text"
                            shape="circle"
                            icon={<QrCode size={24} />}
                            onClick={handleQRCode}
                          />
                        </Tooltip>
                      </Flex>
                    </Col>
                  )}
                  <Col span={24}>
                    <Flex gap={8} justify="space-between" align="center">
                      <Flex vertical gap={2}>
                        <Flex gap={4} align="center">
                          <Paragraph className="mb-0 text-base semi-bold text-content-primary">
                            Customize Domain
                          </Paragraph>
                          <PremiumLogo isTooltip />
                        </Flex>
                        <Paragraph className="mb-0 text-content-tertiary medium">
                          Set up a custom domain for your forms.
                        </Paragraph>
                      </Flex>
                      <CommonButton type="default" isTooltip disabled>
                        Customize
                      </CommonButton>
                    </Flex>
                  </Col>
                  <Col span={24}>
                    <Flex gap={8} justify="space-between" align="center">
                      <Flex vertical gap={2}>
                        <Flex gap={4} align="center">
                          <Paragraph className="mb-0 text-base semi-bold text-content-primary">
                            UTM tracking
                          </Paragraph>
                          <PremiumLogo isTooltip />
                        </Flex>
                        <Paragraph className="mb-0 text-content-tertiary medium">
                          Track your form traffic.
                        </Paragraph>
                      </Flex>
                      <CommonButton
                        type="default"
                        icon={<Plus />}
                        disabled
                        isTooltip
                      >
                        Add values
                      </CommonButton>
                    </Flex>
                  </Col>
                  <Col span={24}>
                    <Divider className="mb-0 mt-0" dashed />
                  </Col>
                  <Col span={24}>
                    <Flex vertical gap={4}>
                      <Paragraph className="mb-0 text-base semi-bold text-content-primary">
                        Form Type
                      </Paragraph>
                      <Form.Item name="formType">
                        <Segmented
                          className="app-segments"
                          options={getFormTypeOptions(false)}
                        />
                      </Form.Item>
                    </Flex>
                  </Col>
                  {details?.type === FormShareType.Embed && (
                    <Col span={24}>
                      <Flex vertical gap={4}>
                        <Paragraph className="mb-0 text-base semi-bold text-content-primary">
                          Embed As
                        </Paragraph>
                        <Form.Item name={['embedConfigs', 'type']}>
                          <Segmented
                            className="vertical-segments embed-share-type"
                            options={menuItems}
                          />
                        </Form.Item>
                      </Flex>
                    </Col>
                  )}

                  {details?.embedConfigs?.type === EmbedTypes.IFRAME && (
                    <>
                      <Col span={24}>
                        <Flex
                          gap={8}
                          justify="space-between"
                          align={!isEmpty(fileList) ? 'flex-start' : 'center'}
                          wrap
                          vertical={!isEmpty(fileList)}
                        >
                          <Paragraph className="mb-0 text-base semi-bold text-content-primary">
                            Show welcome screen
                          </Paragraph>
                          <Form.Item name={['embedConfigs', 'isWelcomePage']}>
                            <CommonSwitch type="primary" />
                          </Form.Item>
                        </Flex>
                      </Col>
                      <Col span={12}>
                        <DimensionInput
                          name={['embedConfigs', 'width']}
                          label="Width"
                        />
                      </Col>
                      <Col span={12}>
                        <DimensionInput
                          name={['embedConfigs', 'height']}
                          label="Height"
                        />
                      </Col>
                    </>
                  )}

                  {details?.type === FormShareType.Link && (
                    <>
                      <Col span={24}>
                        <Flex
                          gap={8}
                          align="center"
                          justify="space-between"
                          wrap
                        >
                          <Flex vertical gap={2}>
                            <Paragraph className="mb-0 text-base semi-bold text-content-primary">
                              Thumbnail/Preview Image
                            </Paragraph>
                            <Paragraph className="mb-0 text-content-tertiary medium">
                              Set the image displayed when sharing your form
                              link.
                            </Paragraph>
                            <Paragraph className="mb-0 text-content-tertiary medium">
                              (Recommended size: 1200x630 in px)
                            </Paragraph>
                          </Flex>
                          <Form.Item
                            name="ogThumbnail"
                            valuePropName="file"
                            getValueFromEvent={(e) => {
                              return e?.fileList?.length
                                ? e?.file?.response
                                : '';
                            }}
                          >
                            <Upload {...uploadProps}>
                              {fileList.length >= 1 ? null : (
                                <CommonButton
                                  type="text"
                                  icon={
                                    <UploadSimple
                                      size={16}
                                      weight="fill"
                                      color="var(--content-primary)"
                                    />
                                  }
                                >
                                  Upload
                                </CommonButton>
                              )}
                            </Upload>
                          </Form.Item>
                        </Flex>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label="Meta Title"
                          name="ogTitle"
                          labelCol={{ span: 24 }}
                        >
                          <Input placeholder="Type here..." />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label="Meta Description"
                          name="ogDescription"
                          labelCol={{ span: 24 }}
                        >
                          <Input.TextArea
                            autoSize={{ minRows: 2 }}
                            placeholder="Type here..."
                            // value={item?.body}
                            // onChange={(e) => {
                            //   onChange({
                            //     id: item?.question_id || '',
                            //     path: ['body'],
                            //     value: [e.target.value],
                            //   });
                            // }}
                          />
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </Row>
              </div>
            </Form>
          ) : (
            <FormLoading />
          )}
        </div>
      </ConfigProvider>
      {qrModalVisible && (
        <CommonQRCodeModal
          qrModalVisible={qrModalVisible}
          handleCloseQRModal={handleCloseQRModal}
          link={link}
        />
      )}
    </>
  );
}
