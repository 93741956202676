import { useMutation } from '@apollo/client';
import {
  Col,
  ConfigProvider,
  DatePicker,
  Divider,
  Flex,
  Form,
  Input,
  Radio,
  Row,
  Tooltip,
} from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import { map } from 'lodash';
import { useContext, useState } from 'react';
import { AppContext } from '../../../../AppContext';
import { FormSetting, FormStatus } from '../../../../__generated__/graphql';
import {
  DarkModeFormIcon,
  LightModeFormIcon,
  SystemModeFormIcon,
} from '../../../../assets/icons';
import { COMING_SOON_TEXT, DROPDOWN_LIST } from '../../../../common/constants';
import { hasFeatureAccess } from '../../../../common/utils';
import PremiumLogo from '../../../../components/common/PremiumLogo';
import CommonButton from '../../../../components/primitives/CommonButton';
import CommonRadio from '../../../../components/primitives/CommonRadio';
import useRouter from '../../../../hooks/useRouter';
import {
  AppActionType,
  AppContextType,
} from '../../../../types/appContext.type';
import { FeatureKeys } from '../../../profile/profile.types';
import { FormMode } from '../../form.types';
import { PUBLISH_FORM } from '../../graphql/mutations';
import CommonCheckLayout from '../layouts/CommonCheckLayout';
import InfoLabel from '../utils/InfoLabel';
import { CommonPageTypes } from './pages.types';

export const FormSettings: React.FC<
  CommonPageTypes & {
    formSettings: FormSetting;
    onChange: (value: FormSetting) => void;
  }
> = ({ onChange, formSettings }) => {
  const [selectedTab, setSelectedTab] = useState<string>(FormMode.AUTO);
  const menuItems = [
    {
      label: 'Light',
      value: FormMode.LIGHT,
      icon: <LightModeFormIcon />,
    },
    {
      label: 'Dark',
      value: FormMode.DARK,
      icon: <DarkModeFormIcon />,
    },
    {
      label: 'Auto',
      value: FormMode.AUTO,
      icon: <SystemModeFormIcon />,
    },
  ];
  const { params } = useRouter();
  const formId = params?.id as string;
  const {
    dispatch,
    state: { formSettings: formData, currentUser },
  } = useContext(AppContext) as AppContextType;

  const [publishFormMutate, { loading: publishLoading }] = useMutation(
    PUBLISH_FORM,
    {
      fetchPolicy: 'network-only',
      variables: {
        where: {
          formId,
        },
      },
      onCompleted: (res) => {
        dispatch({
          type: AppActionType.setFormSettings,
          data: {
            ...res.publishForm?.data,
          },
        });
      },
      onError: () => {},
    },
  );

  const handlePublishBtn = () => {
    publishFormMutate();
  };

  const isPublished = formData?.status === FormStatus.Published;
  const isUpdated = formData?.isUpdated;
  const isDisabled = (isPublished && !isUpdated) || publishLoading;
  const buttonLabel = publishLoading
    ? 'Publishing...'
    : isPublished && isUpdated
      ? 'Republish'
      : 'Publish';

  const allowTagTracking = hasFeatureAccess(
    currentUser?.subscriptionPlan?.features,
    FeatureKeys.TAG_TRACKING,
  );

  return (
    <section>
      <ConfigProvider
        theme={{
          token: {
            colorBgContainer: 'var(--surface-secondary)',
            colorPrimary: '#a9aeb1',
          },
          components: {
            Form: {
              labelColor: 'var(--content-secondary)',
              labelFontSize: 14,
              verticalLabelPadding: 0,
              itemMarginBottom: 0,
            },
            Input: {
              borderRadius: 16,
              colorText: 'var(--content-primary)',
              colorBorder: 'var(--border-primary)',
            },
            Select: {
              borderRadius: 16,
              colorText: 'var(--content-primary)',
              optionActiveBg: 'var(--surface-tertiary)',
              optionSelectedBg: 'var(--surface-tertiary)',
              colorBorder: 'var(--border-primary)',
            },
            DatePicker: {
              borderRadius: 16,
              colorBorder: 'var(--border-primary)',
            },
          },
        }}
      >
        <Flex
          className="form-settings-wrapper mb-32 scroll-wrapper-body"
          gap={20}
          wrap
          vertical
        >
          {/* <Flex gap={8} justify="space-between" align="flex-start">
            <Flex vertical gap={2}>
              <Paragraph className="mb-0 text-m semi-bold text-content-primary">
                Form access
              </Paragraph>
              <Paragraph className="mb-0 text-content-tertiary medium">
                Configure different ways user can fill up the form.
              </Paragraph>
            </Flex>
          </Flex>
          <Flex gap={16} justify="space-between" align="flex-start">
            <Flex vertical gap={2}>
              <Paragraph className="mb-0 text-base semi-bold text-content-primary">
                Default
              </Paragraph>
              <Paragraph className="mb-0 text-content-tertiary medium">
                Our preferred way of collecting information.
              </Paragraph>
            </Flex>
            <CommonSwitch
              type="primary"
              checked={formSettings?.conversational || false}
              onChange={(value) => {
                onChange({
                  conversational: value,
                });
              }}
            />
          </Flex>
          <Flex gap={16} justify="space-between" align="flex-start">
            <Flex vertical gap={2}>
              <Paragraph className="mb-0 text-base semi-bold text-content-primary">
                Chat
              </Paragraph>
              <Paragraph className="mb-0 text-content-tertiary medium">
                Ideal for bot style widgets and messaging platform integrations
                like Slack, Microsoft teams.
              </Paragraph>
            </Flex>
            <CommonSwitch
              type="primary"
              checked={formSettings?.chat || false}
              onChange={(value) => {
                onChange({
                  chat: value,
                });
              }}
            />
          </Flex>
          <Flex gap={16} justify="space-between" align="flex-start">
            <Flex vertical gap={2}>
              <Paragraph className="mb-0 text-base semi-bold text-content-primary">
                Classic
              </Paragraph>
              <Paragraph className="mb-0 text-content-tertiary medium">
                Tested and traditional form style.
              </Paragraph>
            </Flex>
            <CommonSwitch
              type="primary"
              checked={formSettings?.classic || false}
              onChange={(value) => {
                onChange({
                  classic: value,
                });
              }}
            />
          </Flex>
          <Divider className="section-divider" dashed /> */}
          {/* <Flex gap={8} justify="space-between" align="flex-start">
            <Flex vertical gap={2}>
              <Paragraph className="mb-0 text-m semi-bold text-content-primary">
                Conversational Settings
              </Paragraph>
              <Paragraph className="mb-0 text-content-tertiary medium">
                Configure how the conversation will flow.
              </Paragraph>
            </Flex>
          </Flex> */}
          {/* <Flex gap={16} justify="space-between" align="flex-start">
            <Flex vertical gap={2}>
              <Paragraph className="mb-0 text-base medium text-content-primary">
                Generative questions
              </Paragraph>
              <Paragraph className="mb-0 text-content-tertiary medium">
                AI will create conversational responses for a more personalized
                experience.
              </Paragraph>
            </Flex>
            <CommonSwitch
              type="primary"
              checked={formSettings?.generativeQuestion || false}
              onChange={(value) => {
                onChange({
                  generativeQuestion: value,
                });
              }}
            />
          </Flex> */}
          {/* {formSettings?.generativeQuestion && (
            <Row>
              <Col lg={12} xl={8}>
                <Form.Item
                  label={
                    <InfoLabel
                      title="Tone of AI"
                      tooltipText="Set the AI's tone of conversation"
                    />
                  }
                  labelCol={{ span: 24 }}
                >
                  <Select
                    showSearch
                    className="w-full"
                    placeholder="Select"
                    optionFilterProp="label"
                    options={DROPDOWN_LIST.personaList}
                    suffixIcon={
                      <CaretDown size={18} color="var(--content-quarternary)" />
                    }
                    value={formSettings?.tone}
                    onChange={(value) => {
                      onChange({
                        tone: value,
                      });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          )} */}
          {/* <Flex gap={16} justify="space-between" align="flex-start">
            <Flex vertical gap={2}>
              <Paragraph className="mb-0 text-base medium text-content-primary">
                Show response summary before submitting
              </Paragraph>
              <Paragraph className="mb-0 text-content-tertiary medium">
                Respondents will be shown data interpreted by AI at end of form.
              </Paragraph>
            </Flex>
            <CommonSwitch
              type="primary"
              disabled
              checked={formSettings?.finalPreview || false}
              onChange={(value) => {
                onChange({
                  finalPreview: value,
                });
              }}
            />
          </Flex>
          <Divider className="section-divider" dashed /> */}
          <Flex gap={8} justify="space-between" align="flex-start">
            <Flex vertical gap={2}>
              <Flex gap={8} align="center">
                <Paragraph className="mb-0 text-m semi-bold text-content-primary">
                  Form Scheduling
                </Paragraph>
                <span className="flex items-center">
                  <PremiumLogo isTooltip />
                </span>
              </Flex>
              <Paragraph className="mb-0 text-content-tertiary medium">
                The form will only be available during the specified time
                period.
              </Paragraph>
            </Flex>
          </Flex>
          <Row gutter={[16, 16]}>
            <Col span={24} lg={12}>
              <Form.Item
                label={<InfoLabel title="Start from:" />}
                labelCol={{ span: 24 }}
              >
                <DatePicker
                  placeholder="Select start from"
                  className="w-full"
                  showTime
                  disabled
                />
              </Form.Item>
            </Col>
            <Col md={24} lg={12}>
              <Form.Item
                label={<InfoLabel title="End by:" />}
                labelCol={{ span: 24 }}
              >
                <DatePicker
                  placeholder="Select end by"
                  className="w-full"
                  showTime
                  disabled
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={
                  <InfoLabel
                    title="Closing Message"
                    tooltipText="This message will be shown when form is closed/unpublished."
                  />
                }
                labelCol={{ span: 24 }}
              >
                <Input.TextArea
                  disabled
                  placeholder="e.g. Thanks for dropping by, we're no longer open for conversation here..."
                  className="w-full"
                  autoSize={{ minRows: 3 }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider className="section-divider" dashed />

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Flex gap={16} justify="space-between" vertical>
                <Flex vertical gap={2}>
                  <Flex gap={4} align="center">
                    <Paragraph className="mb-0 text-m semi-bold text-content-primary">
                      Storage
                    </Paragraph>
                    <PremiumLogo isTooltip />
                  </Flex>
                  <Paragraph className="mb-0 text-content-tertiary medium">
                    Choose where you would like to store your responses.
                  </Paragraph>
                </Flex>
                <Flex gap={16} justify="space-between" align="center">
                  <Paragraph className="mb-0 text-content-primary text-base medium">
                    Google Drive
                  </Paragraph>
                  <Tooltip title={COMING_SOON_TEXT}>
                    <CommonButton type="text" disabled>
                      Connect
                    </CommonButton>
                  </Tooltip>
                </Flex>
              </Flex>
            </Col>
          </Row>
          <Divider className="section-divider" dashed />
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <Flex gap={8} justify="space-between" align="flex-start">
                <Flex vertical gap={2}>
                  <Paragraph className="mb-0 text-m semi-bold text-content-primary">
                    Notifications
                  </Paragraph>
                  <Paragraph className="mb-0 text-content-tertiary medium">
                    Never miss a form submission from your audience.
                  </Paragraph>
                </Flex>
              </Flex>
            </Col>
            <Col span={24}>
              <Form.Item
                label={
                  <InfoLabel
                    title="Notify me on"
                    tooltipText="Where would you like to be notified"
                  />
                }
                labelCol={{ span: 24 }}
              >
                <Radio.Group
                  onChange={(e) => {
                    onChange({
                      notifyOn: e.target.value,
                    });
                  }}
                  value={formSettings?.notifyOn}
                  className="w-full radio-group-box"
                >
                  <Row gutter={[8, 8]}>
                    {map(DROPDOWN_LIST.notifyMeList, (item, idx) => {
                      return (
                        <Tooltip
                          title={item.isDisabled ? COMING_SOON_TEXT : ''}
                        >
                          <Col span={24} xl={12} key={idx}>
                            <CommonRadio
                              value={item.value}
                              boxType="primary"
                              disabled={item.isDisabled}
                            >
                              {item.label}
                            </CommonRadio>
                          </Col>
                        </Tooltip>
                      );
                    })}
                  </Row>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={
                  <InfoLabel
                    title="Notify when"
                    tooltipText="When would you like to be notified"
                  />
                }
                labelCol={{ span: 24 }}
              >
                <CommonCheckLayout
                  title="Once a day"
                  isPremium
                  description="Receive summary of all submission once a day."
                  checked={formSettings.notifyAtDay || false}
                  switchProps={{ disabled: true }}
                  onChange={(checked) => {
                    onChange({
                      notifyAtDay: checked,
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <CommonCheckLayout
                title="On submission"
                description="Whenever someone fills the form."
                checked={formSettings.notifyAtSubmission || false}
                onChange={(checked) => {
                  onChange({
                    notifyAtSubmission: checked,
                  });
                }}
              />
            </Col>
          </Row>
          <Divider className="section-divider" dashed />
          <Flex gap={8} justify="space-between" align="flex-start">
            <Flex vertical gap={2}>
              <Flex gap={8} align="center">
                <Paragraph className="mb-0 text-m semi-bold text-content-primary">
                  Tracking
                </Paragraph>
                {!allowTagTracking && (
                  <PremiumLogo featureKey={FeatureKeys.TAG_TRACKING} />
                )}
              </Flex>
              <Paragraph className="mb-0 text-content-tertiary medium">
                Set up tracking to monitor your form's performance.
              </Paragraph>
            </Flex>
          </Flex>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label={<InfoLabel title="Google tag manager" />}
                labelCol={{ span: 24 }}
              >
                <Input
                  placeholder="Add your GTM-XXXXXX..."
                  className="w-full"
                  value={formSettings?.tracking?.gtmId}
                  disabled={!allowTagTracking}
                  onChange={(e) => {
                    onChange({
                      tracking: {
                        ...formSettings?.tracking,
                        gtmId: e.target.value,
                      },
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col md={24}>
              <Form.Item
                label={<InfoLabel title="Meta pixel (Facebook)" />}
                labelCol={{ span: 24 }}
              >
                <Input
                  placeholder="Add your Meta pixel ID..."
                  className="w-full"
                  disabled={!allowTagTracking}
                  value={formSettings?.tracking?.pixelId}
                  onChange={(e) => {
                    onChange({
                      tracking: {
                        ...formSettings?.tracking,
                        pixelId: e.target.value,
                      },
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Flex>
        {/* commenting for future use */}
        {/* <Flex gap={8} className="form-save-buttons">
          <CommonButton
            type="primary"
            icon={<PaperPlaneTilt size={16} weight="fill" />}
            iconPosition="end"
            size="large"
            block
            onClick={handlePublishBtn}
            disabled={isDisabled}
          >
            {buttonLabel}
          </CommonButton>
        </Flex> */}
      </ConfigProvider>
    </section>
  );
};
