import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { ArrowRight } from '@phosphor-icons/react';
import { Col, Divider, Flex, Row, Segmented } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import { lowerCase, map, startCase } from 'lodash';
import { useContext, useRef, useState } from 'react';
import { AppContext } from '../../AppContext';
import {
  CreateFormMutation,
  FormCategory,
  FormThemeMode,
  FormType,
  ListFormCategoriesSortOnField,
  SortOrder,
} from '../../__generated__/graphql';
import { AISparkIcon } from '../../assets/icons';
import {
  EVENT_DATA,
  EVENT_NAME,
  ROUTES,
  allowedFormTypes,
  getThemeBackground,
} from '../../common/constants';
import {
  getCookie,
  handleGraphQlError,
  triggerEvent,
} from '../../common/utils';
import CommonFormLimitModal from '../../components/common/CommonFormLimitModal';
import FormTemplateTour from '../../components/common/tours/FormTemplateTour';
import CommonButton from '../../components/primitives/CommonButton';
import useQueryParams from '../../hooks/useQueryParams';
import useRouter from '../../hooks/useRouter';
import { AppActionType, AppContextType } from '../../types/appContext.type';
import FormLoading from '../form/editor/utils/FormLoading';
import { FormThemeType, Palette } from '../form/form.types';
import { CREATE_FORM, USE_FORM_TEMPLATE } from '../form/graphql/mutations';
import { GET_FORM_CATEGORIES_TEMPLATE } from '../form/graphql/queries';

type ExampleCardType = {
  title: string;
  description: string;
  slug: string;
  activeDesign: Palette;
  type: FormThemeType;
};

export default function FormTemplates() {
  const { navigate } = useRouter();
  const [item, setItems] = useState<FormCategory[]>([]);
  const [activeItem, setActiveItem] = useState<string>();
  const templateListRef = useRef<HTMLDivElement>(null);
  const createBlankFormRef = useRef<HTMLDivElement>(null);
  const createWithAiRef = useRef<HTMLDivElement>(null);
  const [openTour, setOpenTour] = useState<boolean>(false);

  const {
    state: { systemThemeMode },
    dispatch,
  } = useContext(AppContext) as AppContextType;
  const { loading } = useQuery(GET_FORM_CATEGORIES_TEMPLATE, {
    variables: {
      filter: {
        showOnWebApp: true,
        templateLimit: 3,
      },
      sort: [
        {
          sortBy: SortOrder.Desc,
          sortOn: ListFormCategoriesSortOnField.Weightage,
        },
      ],
    },
    onCompleted: (res) => {
      setItems(res.formCategories?.data as FormCategory[]);
      setActiveItem(startCase(res.formCategories?.data?.[0]?.name || ''));
      if (!getCookie('form-template-tour')) {
        setOpenTour(true);
      }
    },
  });
  const [createForm, { loading: createFormLoading }] = useMutation(
    CREATE_FORM,
    {
      onCompleted: (res: CreateFormMutation) => {
        dispatch({
          type: AppActionType.setActiveThemeIds,
          data: {
            activeThemeId: '',
            activeThemeVariationId: '',
            activeMode: FormThemeMode.Auto,
          },
        });
        navigate(`${ROUTES.EDITOR}/${res.createForm?.data?.id}`);
      },
    },
  );
  const { getQueryParam } = useQueryParams();
  const queryFormType = getQueryParam('type') as FormType;
  const [isLimitModal, setIsLimitModal] = useState(false);
  const formType = allowedFormTypes.includes(queryFormType)
    ? queryFormType
    : FormType.SimpleForm;

  const handleErrorModal = (error: ApolloError) => {
    const code = error?.graphQLErrors?.[0]?.extensions?.code;
    const message = error?.graphQLErrors?.[0]?.message;

    if (code === 'REACHED_AT_FORM_CREATION_LIMIT') {
      setIsLimitModal(true);
    } else {
      handleGraphQlError(message);
    }
  };

  const ExampleCard = ({
    title,
    description,
    slug,
    type,
    activeDesign,
  }: ExampleCardType) => {
    const [useFormTemplateMutate, { loading }] = useMutation(
      USE_FORM_TEMPLATE,
      { onError: () => {} },
    );
    return (
      <div
        className="example-card"
        style={getThemeBackground(type as FormThemeType, activeDesign)}
      >
        <Title
          level={4}
          className="example-card-title font-secondary semi-bold"
          style={{ color: activeDesign?.textColor }}
        >
          {title}
        </Title>
        <Paragraph
          className="example-card-description text-meta text-neutrals-800"
          ellipsis={{
            rows: 3,
          }}
          style={{ color: activeDesign?.textColor }}
        >
          {description}
        </Paragraph>
        <CommonButton
          size="small"
          onClick={() => {
            triggerEvent(
              EVENT_NAME.USE_TEMPLATE_CLICK,
              EVENT_DATA.USE_TEMPLATE_CLICK,
            );
            useFormTemplateMutate({
              variables: {
                data: {
                  formTemplateSlug: slug,
                },
              },
              onCompleted: (res) => {
                dispatch({
                  type: AppActionType.setActiveThemeIds,
                  data: {
                    activeThemeId: '',
                    activeThemeVariationId: '',
                    activeMode: FormThemeMode.Auto,
                  },
                });
                navigate(`${ROUTES.EDITOR}/${res.useFormTemplate?.data?.id}`);
              },
              onError: (error) => {
                handleErrorModal(error);
              },
            });
          }}
          icon={<ArrowRight color="var(--content-primary)" />}
          loading={loading}
        ></CommonButton>
      </div>
    );
  };

  // commenting for future use
  // const ExampleSkeletonCard = () => {
  //   return (
  //     <div className="example-card">
  //       <Skeleton active className="w-full mb-16" paragraph={{ rows: 2 }} />
  //       <Skeleton.Button active />
  //     </div>
  //   );
  // };

  const handleBlankFormClick = async () => {
    createForm({
      variables: {
        data: {
          prompt: '',
          type: formType,
        },
      },
      onError: (error) => {
        handleErrorModal(error);
      },
    });
  };

  if (loading) {
    return <FormLoading />;
  }
  return (
    <div className="onboarding-wrapper">
      <section className="container" ref={templateListRef}>
        <Flex vertical gap={8}>
          <Title className="font-secondary text-center text-content-primary semi-bold">
            Choose a template
          </Title>
          <Flex vertical>
            <Paragraph className="text-description text-center text-content-secondary">
              Browse our templates to get started quickly and easily.
            </Paragraph>
            <Paragraph className="text-description text-center text-content-secondary mb-32">
              Find your perfect fit and simplify form-building!
            </Paragraph>
          </Flex>
        </Flex>
        <div className="flex justify-center mx-auto max-w-800">
          {activeItem && (
            <Segmented
              defaultValue={activeItem}
              className="app-segments mb-20"
              onChange={(value) => {
                setActiveItem(value);
              }}
              options={map(item, (val) => startCase(val.name!))}
            />
          )}
        </div>
        <Row gutter={[12, 12]}>
          {item?.map(
            (item) =>
              startCase(item.name!) === activeItem &&
              item?.formTemplates?.map((template, idx) => {
                const activeDesign = template?.form?.designs?.[0];

                const mode =
                  activeDesign?.themeMode === FormThemeMode.Auto
                    ? systemThemeMode
                    : activeDesign?.themeMode;

                const activeTheme: Palette =
                  activeDesign?.themeVariation?.colorPalette[
                    lowerCase(mode as FormThemeMode)
                  ];

                return (
                  <Col span={24} md={8} key={idx}>
                    <ExampleCard
                      title={template?.form?.title || ''}
                      description={template?.form?.prompt || ''}
                      slug={template?.slug || ''}
                      activeDesign={activeTheme}
                      type={activeDesign?.themeVariation?.type as FormThemeType}
                    />
                  </Col>
                );
              }),
          )}
        </Row>
      </section>
      <div className="max-divider">
        <Divider orientation="center">
          <span className="text-neutrals-600 text-meta">OR</span>
        </Divider>
      </div>
      <div className="m-auto">
        <Flex gap={32}>
          <div ref={createBlankFormRef}>
            <CommonButton
              size="large"
              type="primary"
              loading={createFormLoading}
              onClick={handleBlankFormClick}
              icon={
                <ArrowRight color="var(--content-inverse-primary)" size={18} />
              }
              iconPosition="end"
            >
              Blank Form
            </CommonButton>
          </div>
          <div ref={createWithAiRef}>
            <CommonButton
              icon={<AISparkIcon />}
              size="large"
              type="default"
              disabled={createFormLoading}
              onClick={() => {
                navigate(`${ROUTES.FORM_CREATE}?type=${formType}`);
              }}
              gradientBorder
            >
              Create with AI
            </CommonButton>
          </div>
        </Flex>
      </div>
      {isLimitModal && (
        <CommonFormLimitModal
          isVisible={isLimitModal}
          onCancel={() => {
            setIsLimitModal(false);
          }}
          onConfirm={() => {
            navigate(ROUTES.PLAN_BILLING);
          }}
        />
      )}
      {openTour && (
        <FormTemplateTour
          refs={[templateListRef, createBlankFormRef, createWithAiRef]}
          open={openTour}
          onOpenChange={setOpenTour}
        />
      )}
    </div>
  );
}
