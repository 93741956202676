import { Col, Divider, Form, Input, Modal, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { MODAL_SIZE, TOAST_MESSAGE } from '../../../common/constants';
import { formValidation } from '../../../common/utils';
import CommonButton from '../../../components/primitives/CommonButton';
import { ErrorTypes } from '../../auth/auth.types';

type DeleteAccountModalType = {
  onOk: () => void;
  onCancel: () => void;
  open: boolean;
  okLoading: boolean;
};

const DeleteAccountModal = ({
  onOk,
  onCancel,
  open,
  okLoading,
}: DeleteAccountModalType) => {
  const ModalTitle = () => <Title level={4}>Delete Account</Title>;
  const [errors, setErrors] = useState<ErrorTypes>({});

  const handleFinish = ({ deleteValue }: { deleteValue: string }) => {
    setErrors({});
    if (deleteValue.trim() === 'delete') {
      // call API
      onOk();
    } else {
      setErrors({
        deleteValue: TOAST_MESSAGE.invalidInput,
      });
    }
  };

  const handleFieldsChange = () => {
    if (!isEmpty(errors)) {
      setErrors({});
    }
  };

  return (
    <>
      <Modal
        title={<ModalTitle />}
        open={open}
        onOk={onOk}
        closeIcon={null}
        onCancel={onCancel}
        footer={null}
        width={MODAL_SIZE.medium}
        destroyOnClose
      >
        <p className="text-sm text-neutrals-700">
          Please take a moment to review what will happen when your account is
          deleted as this action is permanent and not reversible.
        </p>
        <Divider dashed className="mt-12 mb-12" />
        <p className="text-sm text-neutrals-700 mb-12">
          This action is permanent, please confirm your understanding by typing
          <span className="text-neutrals-900"> ‘delete’</span>
        </p>
        <Form
          name="delete-form"
          onFinish={handleFinish}
          layout="vertical"
          scrollToFirstError
          onFieldsChange={handleFieldsChange}
          autoComplete="off"
        >
          <Form.Item
            name="deleteValue"
            rules={[formValidation.required, formValidation.whitespace]}
            className="mb-24"
            validateStatus={errors.deleteValue ? 'error' : ''}
            help={errors.deleteValue}
          >
            <Input placeholder="Type here..." />
          </Form.Item>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item className="mb-0">
                <CommonButton
                  title="Cancel"
                  htmlType="button"
                  type="default"
                  block
                  onClick={onCancel}
                  disabled={okLoading}
                >
                  Cancel
                </CommonButton>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item className="mb-0">
                <CommonButton
                  type="primary"
                  htmlType="submit"
                  block
                  loading={okLoading}
                >
                  Confirm
                </CommonButton>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default DeleteAccountModal;
