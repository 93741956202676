import { Flex } from 'antd';
import { map } from 'lodash';
import {
  MockPageLayoutSvg,
  MockPageLayoutVerticalSvg,
} from '../../../../assets/icons';
import { PageLayoutType } from '../../form.types';

type PageLayoutOptionType = {
  value: PageLayoutType;
  onChange: (value: PageLayoutType) => void;
};
export default function PageLayoutOption({
  value,
  onChange,
}: PageLayoutOptionType) {
  const PageFilled = () => (
    <Flex
      className="page-filled"
      vertical
      gap={12}
      align="center"
      justify="center"
    >
      <Flex gap={4} vertical align="center">
        <div className="mock-bar"></div>
        <div className="mock-sub-bar"></div>
      </Flex>
      <div className="mock-button"></div>
    </Flex>
  );
  const PageLeft = () => (
    <Flex className="page-left" gap={4} align="center" justify="center">
      <MockPageLayoutVerticalSvg />
      <Flex gap={2} vertical align="flex-start">
        <div className="mock-bar"></div>
        <div className="mock-sub-bar mb-4"></div>
        <div className="mock-button"></div>
      </Flex>
    </Flex>
  );
  const PageTopCenter = () => (
    <Flex
      className="page-top-center"
      vertical
      gap={4}
      align="center"
      justify="center"
    >
      <Flex gap={2} vertical align="center">
        <div className="mb-4">
          <MockPageLayoutSvg />
        </div>
        <div className="mock-bar"></div>
        <div className="mock-sub-bar"></div>
      </Flex>
      <div className="mock-button"></div>
    </Flex>
  );

  const objectMapping = {
    [PageLayoutType.PAGE_FILLED]: <PageFilled />,
    [PageLayoutType.PAGE_LEFT]: <PageLeft />,
    [PageLayoutType.PAGE_TOP_CENTER]: <PageTopCenter />,
  };
  return (
    <Flex className="page-layout-option-wrapper" gap={12} wrap>
      {map(Object.keys(PageLayoutType), (item: PageLayoutType, idx: number) => {
        return (
          <div
            key={idx}
            className={`${item === value ? `box active ${item === PageLayoutType.PAGE_FILLED ? 'box-bg' : ''}` : `box ${item === PageLayoutType.PAGE_FILLED ? 'box-bg' : ''}`}`}
            onClick={() => {
              onChange(item);
            }}
          >
            {objectMapping?.[item]}
          </div>
        );
      })}
    </Flex>
  );
}
