'use Client';
import { useMutation, useQuery } from '@apollo/client';
import { Check } from '@phosphor-icons/react';
import { Button, Modal, Skeleton } from 'antd';
import Title from 'antd/es/typography/Title';
import { lowerCase, map } from 'lodash';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  SubscriptionPlan,
  SubscriptionPlanType,
} from '../../__generated__/graphql';
import { AppContext } from '../../AppContext';
import subscribeBackground from '../../assets/images/subscribe-modal-bg.png';
import { DEFAULT_PLAN_SORT, ROUTES } from '../../common/constants';
import { currencyCodeToSymbol } from '../../common/utils';
import { ACTIVATE_SUBSCRIPTION_PLAN } from '../../modules/profile/graphql/mutations';
import { GET_PLAN_LIST } from '../../modules/profile/graphql/queries';
import { PlanType } from '../../modules/profile/profile.types';
import { AppActionType, AppContextType } from '../../types/appContext.type';
import CommonTag from '../primitives/CommonTag';
import PremiumLogo from './PremiumLogo';

type SubscribeModalProps = {
  visible: boolean;
  onCancel: () => void;
  onUpgrade: () => void;
};

const SubscribeModal: React.FC<SubscribeModalProps> = ({
  visible,
  onCancel,
  onUpgrade,
}) => {
  const { loading, refetch, data } = useQuery(GET_PLAN_LIST, {
    variables: {
      sort: DEFAULT_PLAN_SORT,
    },
  });
  const { dispatch } = useContext(AppContext) as AppContextType;
  const [activateSubscriptionPlan, { loading: activateLoading }] = useMutation(
    ACTIVATE_SUBSCRIPTION_PLAN,
    {
      onError() {},
    },
  );

  const FeatureListItem = ({ list }: { list: string[] }) => {
    return (
      <ul className="mt-16">
        {map(list, (text) => {
          return (
            <li>
              <Check color="var(--system-green-dark)" size={20} weight="bold" />
              {text}
            </li>
          );
        })}
        <Link to={ROUTES.PLAN_BILLING} className="w-max-content">
          <li className="text-content-inverse-primary text-underline">
            <svg height={16} width={16}></svg>& More...
          </li>
        </Link>
      </ul>
    );
  };

  const handleActivatePlan = (id: string) => {
    activateSubscriptionPlan({
      variables: {
        data: {
          subscriptionPlanId: id,
        },
      },
      onCompleted: (res) => {
        refetch();
        dispatch({
          type: AppActionType.setCurrentUser,
          data: {
            subscriptionPlan: {
              features: res?.activateSubscriptionPlan?.data?.features,
            },
          },
        });
        onUpgrade();
      },
    });
  };

  return (
    <Modal
      width={1168}
      className="subscribe-modal"
      open={visible}
      footer={false}
      onCancel={onCancel}
    >
      <img src={subscribeBackground} className="cover-img" alt="" />
      <div className="subscribe-modal-wrapper">
        <Title className="gradient-text">Subscribe to Premium Plan</Title>
        <div className="subscribe-plan-card-wrapper">
          {map(
            data?.subscriptionPlanKeyFeatures?.data,
            (item: SubscriptionPlan) => {
              return (
                <div className="subscribe-plan-card">
                  {lowerCase(item.label!) !== PlanType.FREE ? (
                    <div className="tag premium mb-8">
                      PREMIUM <PremiumLogo />
                    </div>
                  ) : (
                    <div className="tag mb-8">{item.label}</div>
                  )}

                  <Title level={2}>
                    {lowerCase(item.label!) !== PlanType.FREE &&
                    item.type == SubscriptionPlanType.Monthly
                      ? `Premium (Beta - ${currencyCodeToSymbol(item.currency || '')}${item.price || 0})`
                      : `${currencyCodeToSymbol(item.currency || '')}${item.price || 0}`}
                  </Title>
                  {/* <p className="text-sm mt-16">{item.description}</p> */}
                  {item.keyFeatures && (
                    <FeatureListItem list={item.keyFeatures as string[]} />
                  )}
                  <div className="plan-button">
                    {lowerCase(item.label!) !== PlanType.FREE ? (
                      <>
                        <Link to={ROUTES.PLAN_BILLING}>
                          <Button type="text">Compare plans</Button>
                        </Link>
                        <div className="plan-button-account-page">
                          {item.isSubscribed ? (
                            <CommonTag className="w-max-content">
                              Current Plan
                            </CommonTag>
                          ) : (
                            <Button
                              type="primary"
                              onClick={() => handleActivatePlan(item.id!)}
                              loading={activateLoading}
                            >
                              Upgrade Now For Free
                            </Button>
                          )}
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              );
            },
          )}
          {loading && <Skeleton active />}
        </div>
        <div className="text-center text-sm mt-36 subscribe-modal-footer-text">
          *Premium is available for FREE while we are in beta.
        </div>
      </div>
    </Modal>
  );
};

export default SubscribeModal;
