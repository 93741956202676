import { Flex } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import { lowerCase, map } from 'lodash';
import { useContext } from 'react';
import { AppContext } from '../../../../AppContext';
import {
  FormDesign,
  FormTheme,
  FormThemeMode,
} from '../../../../__generated__/graphql';
import {
  AppActionType,
  AppContextType,
} from '../../../../types/appContext.type';
import { FormThemeType, Palette } from '../../../form/form.types';

type ThemeListType = {
  themeList?: FormTheme[];
  formThemeMode: FormThemeMode;
  value?: string;
  onChange: (value: FormDesign) => void;
};

export default function ThemeList({
  themeList,
  formThemeMode,
  value,
  onChange,
}: ThemeListType) {
  const {
    state: { systemThemeMode },
    dispatch,
  } = useContext(AppContext) as AppContextType;

  const renderThemeCard = (theme: FormTheme) => {
    const mode =
      formThemeMode === FormThemeMode.Auto ? systemThemeMode : formThemeMode;

    const style: Palette =
      theme?.configs?.colorPalette?.[lowerCase(mode as string)];

    switch (theme?.type) {
      case FormThemeType.SOLID:
        return (
          <Flex
            className={`card ${theme.id === value ? 'active' : ''}`}
            onClick={() => {
              onChange({
                themeId: theme?.id,
                themeVariationId: theme?.themeVariations?.[0]?.id,
              });
              dispatch({
                type: AppActionType.setActiveThemeIds,
                data: {
                  activeThemeId: theme?.id,
                  activeThemeVariationId: theme?.themeVariations?.[0]?.id,
                },
              });
            }}
            vertical
            style={{
              backgroundColor: style?.bgPrimary,
            }}
          >
            <Paragraph
              className="mb-0 text-micro"
              style={{
                color:
                  mode === FormThemeMode.Dark
                    ? 'var(--content-inverse-primary)'
                    : 'var(--content-primary)',
              }}
            >
              FONT
            </Paragraph>
            <Paragraph
              className="mb-0 semi-bold"
              style={{
                fontFamily: style?.fontFamily,
                color: style?.textColor,
              }}
            >
              {theme.configs?.colorPalette?.dark?.fontFamily}
            </Paragraph>
          </Flex>
        );
      case FormThemeType.GRADIENT:
        return (
          <Flex
            className={`card ${theme.id === value ? 'active' : ''}`}
            onClick={() => {
              onChange({
                themeId: theme?.id,
                themeVariationId: theme?.themeVariations?.[0]?.id,
              });
              dispatch({
                type: AppActionType.setActiveThemeIds,
                data: {
                  activeThemeId: theme?.id,
                  activeThemeVariationId: theme?.themeVariations?.[0]?.id,
                },
              });
            }}
            vertical
            style={{
              background: `linear-gradient(${style?.gradientDegree}deg, ${style?.bgPrimary} 1.27%, ${style?.bgSecondary} 70.38%)`,
            }}
          >
            <Paragraph
              className="mb-0 text-micro"
              style={{
                color:
                  mode === FormThemeMode.Dark
                    ? 'var(--content-inverse-primary)'
                    : 'var(--content-primary)',
              }}
            >
              FONT
            </Paragraph>
            <Paragraph
              className="mb-0 semi-bold"
              style={{
                fontFamily: style?.fontFamily,
                color: style?.textColor,
              }}
            >
              {theme.configs?.colorPalette?.dark?.fontFamily}
            </Paragraph>
          </Flex>
        );
      case FormThemeType.PATTERN:
        return (
          <Flex
            className={`card ${theme.id === value ? 'active' : ''}`}
            onClick={() => {
              onChange({
                themeId: theme?.id,
                themeVariationId: theme?.themeVariations?.[0]?.id,
              });
              dispatch({
                type: AppActionType.setActiveThemeIds,
                data: {
                  activeThemeId: theme?.id,
                  activeThemeVariationId: theme?.themeVariations?.[0]?.id,
                },
              });
            }}
            vertical
            style={{
              backgroundImage: `url('${style?.bgImage}')`,
              backgroundColor: style?.bgPrimary,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <Paragraph
              className="mb-0 text-micro"
              style={{
                color:
                  mode === FormThemeMode.Dark
                    ? 'var(--content-inverse-primary)'
                    : 'var(--content-primary)',
              }}
            >
              FONT
            </Paragraph>
            <Paragraph
              className="mb-0 semi-bold"
              style={{
                fontFamily: style?.fontFamily,
                color: style?.textColor,
              }}
            >
              {theme.configs?.colorPalette?.dark?.fontFamily}
            </Paragraph>
          </Flex>
        );

      default:
        break;
    }
  };
  return (
    <Flex gap={12} className="theme-list-wrapper" wrap>
      {map(themeList, (theme) => renderThemeCard(theme))}
    </Flex>
  );
}
