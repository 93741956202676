import { PlusCircle } from '@phosphor-icons/react';
import { Flex } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import { useContext, useEffect, useState } from 'react';
import { FormType } from '../../../__generated__/graphql';
import { AppContext } from '../../../AppContext';
import { EditCardSvg, StarCardSvg } from '../../../assets/icons';
import { EVENT_DATA, EVENT_NAME, ROUTES } from '../../../common/constants';
import { hasFeatureAccess, triggerEvent } from '../../../common/utils';
import FormTypeModal from '../../../components/common/FormTypeModal';
import SubscribeModal from '../../../components/common/SubscribeModal';
import UpgradeSuccessful from '../../../components/common/UpgradeSuccessful';
import useRouter from '../../../hooks/useRouter';
import { AppContextType } from '../../../types/appContext.type';
import { FeatureKeys } from '../../profile/profile.types';

export default function CreateCard({ formCount }: { formCount: number }) {
  const { navigate } = useRouter();
  const {
    state: { currentUser },
  } = useContext(AppContext) as AppContextType;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSubscribeModalOpen, setIsSubscribeModalOpen] =
    useState<boolean>(false);
  const [upgradeModal, setUpgradeModal] = useState<boolean>(false);

  const handleClick = () => {
    triggerEvent(EVENT_NAME.CREATE_CARD_CLICK, EVENT_DATA.CREATE_CARD_CLICK);
    // check if user has access to form creation limit
    const formCreationLimit =
      hasFeatureAccess(
        currentUser?.subscriptionPlan?.features,
        FeatureKeys.FORM_CREATION_LIMIT,
      ) || 0;

    if (formCreationLimit !== -1 && formCount >= formCreationLimit) {
      // show subscribe modal
      setIsSubscribeModalOpen(true);
    } else {
      // if user has access to form creation limit, open the modal
      setIsModalOpen(true);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        event.preventDefault();
        handleClick();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleModalCancel = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleBlankFormClick = (formType: FormType) => {
    setIsModalOpen(false);
    navigate(`${ROUTES.FORM_TEMPLATES}?type=${formType}`);
  };

  const handleSubscribeModalCancel = () => {
    setIsSubscribeModalOpen(false);
  };

  return (
    <>
      <div className="create-card" onClick={handleClick}>
        <Flex vertical gap={12} align="center">
          <PlusCircle color="var(--content-primary)" size={40} weight="fill" />
          <Flex gap={4} vertical>
            <Title
              level={4}
              className="mb-0 text-center text-content-primary font-secondary"
            >
              New Form
            </Title>
            <Paragraph className="mb-0 text-center text-content-secondary">
              (Ctrl/⌘ + K)
            </Paragraph>
          </Flex>
        </Flex>
      </div>
      <FormTypeModal
        open={isModalOpen}
        onCancel={() => {
          handleModalCancel();
        }}
        title="New Form"
        description="Select an option to continue"
        cards={[
          {
            title: 'Smart Form',
            description:
              'An end-to-end AI form creation and fill-out experience designed to captivate its recipients.',
            buttonText: 'Coming soon',
            onClick: () => handleBlankFormClick(FormType.SmartForm),
            isPremium: true,
            cardIcon: <StarCardSvg />,
          },
          {
            title: 'Simple Form',
            description:
              'Created with AI, designed to deliver a classic form filling experience to its recipients.',
            buttonText: 'Create',
            onClick: () => handleBlankFormClick(FormType.SimpleForm),
            cardIcon: <EditCardSvg />,
          },
        ]}
      />
      {isSubscribeModalOpen && (
        <SubscribeModal
          visible={isSubscribeModalOpen}
          onCancel={handleSubscribeModalCancel}
          onUpgrade={() => {
            setIsSubscribeModalOpen(false);
            setUpgradeModal(true);
          }}
        />
      )}
      {upgradeModal && (
        <UpgradeSuccessful
          isVisible={upgradeModal}
          onClose={() => {
            setUpgradeModal(false);
          }}
        />
      )}
    </>
  );
}
