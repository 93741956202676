import { useLazyQuery } from '@apollo/client';
import { Col, Row } from 'antd';
import { map, times } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import {
  Form,
  ListFormSortOnField,
  SortOrder,
} from '../../__generated__/graphql';
import {
  BREAKPOINT,
  DEFAULT_ITEMS_PER_PAGE,
  initialPaginationFilter,
} from '../../common/constants';
import CreateCard from './cards/CreateCard';
import FeedbackCard from './cards/FeedbackCard';
import SkeletonCard from './cards/SkeletonCard';
import { GET_FORM_LIST } from './graphql/queries';

export default function Home() {
  const [items, setItems] = useState<Form[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [count, setCount] = useState(0);
  const defaultSorting = [
    {
      sortBy: SortOrder.Desc,
      sortOn: ListFormSortOnField.CreatedAt,
    },
  ];

  // fetch workspace forms
  const [fetchFormList, { loading }] = useLazyQuery(GET_FORM_LIST, {
    fetchPolicy: 'cache-and-network',
    variables: {
      filter: initialPaginationFilter,
      sort: defaultSorting,
    },
    onCompleted: (res) => {
      setItems(res.forms?.data as Form[]);
      setHasMore(Number(res.forms?.count) > DEFAULT_ITEMS_PER_PAGE);
      setCount(Number(res.forms?.count));
    },
    onError: () => {},
  });

  useEffect(() => {
    fetchFormList();
  }, []);

  // load more func when last element becomes visible
  const loadMore = () => {
    fetchFormList({
      variables: {
        filter: {
          limit: DEFAULT_ITEMS_PER_PAGE,
          skip: items.length,
        },
        sort: defaultSorting,
      },
      onCompleted: (res) => {
        const mergedData = [...items, ...(res.forms?.data as Form[])];
        setItems(mergedData);
        setHasMore(Number(res.forms?.count) > mergedData.length);
      },
    });
  };

  const observer = useRef<IntersectionObserver | null>(null);

  const lastItemRef = (node: HTMLDivElement | null) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        loadMore();
      }
    });
    if (node) observer.current.observe(node);
  };

  const skeletonCards = () =>
    times(5, (idx) => {
      return (
        <Col {...BREAKPOINT.cardBreakpoint} key={idx}>
          <SkeletonCard />
        </Col>
      );
    });

  return (
    <div className="home-wrapper">
      <Row gutter={[24, 24]}>
        <Col {...BREAKPOINT.cardBreakpoint}>
          <CreateCard formCount={count} />
        </Col>
        {map(items, (form, idx) => {
          return (
            <Col
              {...BREAKPOINT.cardBreakpoint}
              key={idx}
              ref={idx === items.length - 1 ? lastItemRef : null}
            >
              <FeedbackCard
                onDelete={() => {
                  fetchFormList();
                }}
                onDuplicate={() => {
                  fetchFormList();
                }}
                {...form}
              />
            </Col>
          );
        })}
        {loading && skeletonCards()}
      </Row>
    </div>
  );
}
