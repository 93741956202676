import { ApolloError, useMutation } from '@apollo/client';
import { Col, ConfigProvider, Divider, Flex, Form, Row } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import { map } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import {
  CreateFormInput,
  CreateFormMutation,
  FormThemeMode,
  FormType,
} from '../../__generated__/graphql';
import { AppContext } from '../../AppContext';
import { allowedFormTypes, ROUTES } from '../../common/constants';
import { formValidation, getCookie } from '../../common/utils';
import CommonFormLimitModal from '../../components/common/CommonFormLimitModal';
import PromptScreenTour from '../../components/common/tours/PromptScreenTour';
import CommonButton from '../../components/primitives/CommonButton';
import useQueryParams from '../../hooks/useQueryParams';
import useRouter from '../../hooks/useRouter';
import { AppActionType, AppContextType } from '../../types/appContext.type';
import { CREATE_FORM } from '../form/graphql/mutations';

export default function PromptScreen() {
  const { navigate } = useRouter();
  const [form] = Form.useForm();
  const { getQueryParam } = useQueryParams();
  const queryFormType = getQueryParam('type') as FormType;
  const [isLimitModalOpen, setIsLimitModalOpen] = useState(false);
  const { dispatch } = useContext(AppContext) as AppContextType;
  const [openTour, setOpenTour] = useState(false);
  const inputRef = useRef<HTMLDivElement>(null);
  const exampleListRef = useRef<HTMLDivElement>(null);
  const promptScreenTour = getCookie('prompt-screen-tour');

  const formType = allowedFormTypes.includes(queryFormType)
    ? queryFormType
    : FormType.SimpleForm;

  const promptList = [
    'Create a job application form for my tech company that allows candidates to upload resumes and cover letters.',
    'Create a startup summit registration form and ask them about contact details, dietary restrictions, and topics they are interested in in the panel discussion.',
    'Generate an anonymous employee engagement survey to assess employee morale and identify areas for improvement in the company.',
  ];

  const [createForm, { loading }] = useMutation(CREATE_FORM, {
    onCompleted: (res: CreateFormMutation) => {
      // clean up active theme ids
      dispatch({
        type: AppActionType.setActiveThemeIds,
        data: {
          activeThemeId: '',
          activeThemeVariationId: '',
          activeMode: FormThemeMode.Auto,
        },
      });
      navigate(`${ROUTES.EDITOR}/${res.createForm?.data?.id}`);
    },
  });

  const ExampleCard = ({
    description,
    onClick,
  }: {
    description: string;
    onClick: () => void;
  }) => {
    return (
      <Col lg={8}>
        <div className="card-box cursor-pointer" onClick={onClick}>
          <Paragraph className="card-heading">{'// EXAMPLE'}</Paragraph>
          <Divider className="mt-8 mb-8" />
          <Paragraph className="text-content-secondary" ellipsis={{ rows: 2 }}>
            {description}
          </Paragraph>
        </div>
      </Col>
    );
  };

  const handleKeyDown = async (
    event: React.KeyboardEvent<HTMLTextAreaElement>,
  ) => {
    // Check if the Enter key is pressed and no modifier keys are held down
    if (
      event.key === 'Enter' &&
      !event.shiftKey &&
      !event.ctrlKey &&
      !event.altKey
    ) {
      event.preventDefault();
      try {
        await form.validateFields();
        form.submit();
      } catch (e) {
        return e;
      }
    }
  };

  const handleErrorModal = (error: ApolloError) => {
    const code = error?.graphQLErrors?.[0]?.extensions?.code;
    const message = error?.graphQLErrors?.[0]?.message;

    if (code === 'REACHED_AT_FORM_CREATION_LIMIT') {
      setIsLimitModalOpen(true);
    } else {
      form.setFields([
        {
          name: 'prompt',
          errors: [message],
        },
      ]);
    }
  };

  const handleSubmit = async (formValues: CreateFormInput) => {
    createForm({
      variables: {
        data: {
          prompt: formValues.prompt,
          type: formType,
        },
      },
      onError: (error) => {
        handleErrorModal(error);
      },
    });
  };

  useEffect(() => {
    if (!promptScreenTour) {
      setOpenTour(true);
    }
  }, [promptScreenTour]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            colorBorder: 'var(--border-primary)',
            borderRadius: 16,
            lineWidth: 1,
            fontSize: 16,
            colorText: 'var(--content-primary)',
            colorBgContainer: 'var(--surface-primary)',
          },
        },
      }}
    >
      <div className="onboarding-wrapper">
        <Flex ref={inputRef} className="container" vertical gap={32}>
          <Flex vertical gap={8}>
            <Title className="font-secondary text-center mb-8 semi-bold">
              Create with AI
            </Title>
            <Flex vertical>
              <Paragraph className="text-description text-center text-content-secondary">
                Just type your request clearly. Be specific for best results.
              </Paragraph>
              <Paragraph className="text-description text-center text-content-secondary">
                Let AI handle the rest!
              </Paragraph>
            </Flex>
          </Flex>
          <Form form={form} className="prompt-form" onFinish={handleSubmit}>
            <div className="information-wrapper">
              <div className="relative">
                <Form.Item name="prompt" rules={[formValidation.required]}>
                  <TextArea
                    autoSize={{ minRows: 3 }}
                    placeholder="Start typing...."
                    className="input-prompt"
                    onKeyDown={handleKeyDown}
                    maxLength={500}
                  />
                </Form.Item>
                {/* commenting for future use */}
                {/* <div className="float-microphone">
                  <Microphone
                    size={20}
                    weight="fill"
                    color="var(--content-tertiary)"
                  />
                </div> */}
              </div>
              <Flex justify="center">
                <CommonButton
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  size="large"
                >
                  Submit
                </CommonButton>
              </Flex>
            </div>
          </Form>
        </Flex>
        <section className="container">
          <Row className="example-list" gutter={[16, 16]} ref={exampleListRef}>
            {map(promptList, (prompt) => (
              <ExampleCard
                description={prompt}
                onClick={() => {
                  form.setFields([
                    {
                      name: 'prompt',
                      value: prompt,
                      errors: [],
                    },
                  ]);
                }}
              />
            ))}
          </Row>
        </section>
      </div>
      {isLimitModalOpen && (
        <CommonFormLimitModal
          isVisible={isLimitModalOpen}
          onCancel={() => setIsLimitModalOpen(false)}
          onConfirm={() => {
            navigate(ROUTES.PLAN_BILLING);
          }}
        />
      )}
      {openTour && (
        <PromptScreenTour
          refs={[inputRef, exampleListRef]}
          open={openTour}
          onOpenChange={setOpenTour}
        />
      )}
    </ConfigProvider>
  );
}
